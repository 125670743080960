import { Box, Icon, Input, Link, Text, useMediaQuery } from '@chakra-ui/react'
import { CheckIcon, CloseIcon, DeleteIcon, EditIcon } from '@chakra-ui/icons'
import { FC, RefObject, useEffect, useRef, useState } from 'react'
import {
  addFolderClean,
  addFolderRequest,
  deleteFolderRequest,
  getFavoritesByFolderClean,
  getFavoritesByFolderRequest,
  getFoldersClean,
  getFoldersRequest,
  renameFolderRequest,
} from '../../../state/modules/favorites/actions'
import { useDispatch, useSelector } from 'react-redux'

import ActionPopover from '../../actionPopover'
import EditInput from '../EditInput'
import { FavoritesFoldersProps } from './_types'
import Loader from '../../loader'
import { MdOutlineFolder } from 'react-icons/md'
import { favoritesFoldersStyles } from './styles'

const FavoritesFolders: FC<FavoritesFoldersProps> = ({
  selectedFolder,
  setSelectedFolder,
  setCurrentPage,
}) => {
  const foldersList: any = useSelector((state: any) => state?.favorites?.foldersListStatus?.folders)
  const [isMobile] = useMediaQuery('(max-width: 600px)')
  const [addFolder, setAddFolder] = useState<boolean>(false)
  const [editFolder, setEditFolder] = useState<string | null>(null)
  const [isEmpty, setIsEmpty] = useState<boolean>(false)

  const dispatch = useDispatch()

  const loaderStyles = {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100px',
  }

  const loaderMobileStyles = {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100px',
  }

  const handleSelectFolder = (id: string | null) => {
    setSelectedFolder(id)
    setCurrentPage(1)
  }

  const handleAddFolder = () => {
    setEditFolder(null)
    setAddFolder(true)
  }

  const handleCreateFolder = (name: string) => {
    const token = localStorage.getItem('token') ?? ''

    if (name === '') {
      setIsEmpty(true)
    } else {
      setIsEmpty(false)
      dispatch(addFolderRequest({ token, name }))
      setAddFolder(false)
    }
  }

  const handleRenameFolder = (name: string) => {
    const token = localStorage.getItem('token') ?? ''

    if (name === '') {
      setIsEmpty(true)
    } else {
      setIsEmpty(false)
      dispatch(renameFolderRequest({ token, folderId: editFolder, name }))
      setEditFolder(null)
    }
  }

  const handleEditFolder = (id: string) => {
    setEditFolder(id)
    setAddFolder(false)
  }

  const handleClosePopover = (id: string) => {
    const button = document.getElementById(`PopoverCloseButton${id}`)
    button?.click()
  }

  const handleRemoveFolder = (id: string) => {
    const token = localStorage.getItem('token') ?? ''
    dispatch(deleteFolderRequest({ token, folderId: id }))
  }

  return (
    <Box sx={favoritesFoldersStyles.container}>
      {foldersList ? (
        <>
          <Box sx={favoritesFoldersStyles.folderContainer}>
            <Box sx={favoritesFoldersStyles.folder}>
              <Text
                fontWeight={selectedFolder === null ? 'bold' : 'normal'}
                _hover={{ textDecoration: 'underline' }}
                sx={favoritesFoldersStyles.folderText}
                onClick={() => handleSelectFolder(null)}
              >
                <Icon
                  as={MdOutlineFolder as React.ElementType}
                  sx={favoritesFoldersStyles.folderIcon}
                  boxSize={8}
                />
                General ({foldersList.at(-1).totalFavorites})
              </Text>
            </Box>
            {foldersList
              ?.filter((folder: any) => folder.id)
              .map((folder: any) => (
                <Box key={folder.id}>
                  {editFolder === folder.id ? (
                    <EditInput
                      editedText={folder.name}
                      onConfirm={handleRenameFolder}
                      onClose={() => {
                        setEditFolder(null)
                        setIsEmpty(false)
                      }}
                      setIsEmpty={setIsEmpty}
                      isEmpty={isEmpty}
                    />
                  ) : (
                    <Box sx={favoritesFoldersStyles.folder}>
                      <Text
                        fontWeight={selectedFolder === folder.id ? 'bold' : 'normal'}
                        _hover={{ textDecoration: 'underline' }}
                        sx={favoritesFoldersStyles.folderText}
                        onClick={() => handleSelectFolder(folder.id)}
                      >
                        <Icon
                          as={MdOutlineFolder as React.ElementType}
                          sx={favoritesFoldersStyles.folderIcon}
                          boxSize={8}
                        />
                        {folder.name} ({folder.totalFavorites})
                      </Text>
                      <Box sx={favoritesFoldersStyles.actionContainer}>
                        <Icon
                          sx={favoritesFoldersStyles.editIcon}
                          as={EditIcon as React.ElementType}
                          onClick={() => handleEditFolder(folder.id)}
                        />
                        <ActionPopover
                          handleAction={handleRemoveFolder}
                          id={folder.id}
                          title={'¿Estás seguro?'}
                          handleClose={handleClosePopover}
                          buttonSize='12px'
                        >
                          <Icon
                            sx={favoritesFoldersStyles.deleteIcon}
                            as={DeleteIcon as React.ElementType}
                          />
                        </ActionPopover>
                      </Box>
                    </Box>
                  )}
                </Box>
              ))}
          </Box>
          {addFolder ? (
            <EditInput
              onConfirm={handleCreateFolder}
              onClose={() => {
                setAddFolder(false)
                setIsEmpty(false)
              }}
              setIsEmpty={setIsEmpty}
              isEmpty={isEmpty}
            />
          ) : (
            <Link sx={favoritesFoldersStyles.addLink} onClick={handleAddFolder}>
              Agregar carpeta +
            </Link>
          )}
        </>
      ) : (
        <Loader
          props={isMobile ? loaderMobileStyles : loaderStyles}
          size={isMobile ? 'xl' : 'lg'}
        />
      )}
    </Box>
  )
}

export default FavoritesFolders
