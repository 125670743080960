import * as Yup from 'yup'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Field, Form, Formik, FormikErrors, FormikTouched } from 'formik'
import { Box, Button, FormControl, FormLabel, Input, Text } from '@chakra-ui/react'

import { FilterFields, InstitutionsFilterProps } from './_types'

import { institutionsFilterStyles } from './styles'

import { institutionFields } from '../../../../utils/abm'

import { downloadInstitutionSheetRequest } from '../../../../state/modules/abm/actions'
import { EmailRegex } from '../../../../utils/regexTypes'

const InstitutionsFilter: React.FC<InstitutionsFilterProps> = ({
  setSearchFilter,
  setCurrentPage,
}) => {
  const dispatch = useDispatch()

  const filterFields = institutionFields
    .filter((e) => e.isFiltered)
    .sort((e1, e2) => e1.order - e2.order)
  const initialValues: FilterFields = {
    cue: '',
    provincialNumber: '',
    institutionName: '',
    email: '',
  }

  const validationSchema = Yup.object({
    email: Yup.string().email('Email no válido').matches(EmailRegex, 'Email no válido'),
    cue: Yup.number()
      .typeError('Solo se permiten números')
      .max(999999999999999, 'Maximo 15 caracteres'),
    provincialNumber: Yup.number()
      .typeError('Solo se permiten números')
      .max(9999999999, 'Maximo 10 caracteres'),
  }).defined()

  const handleSubmit = (values: FilterFields) => {
    setCurrentPage(1)

    const updatedValues = {
      email: values.email,
      institutionName: values.institutionName,
      cue: values.cue.toString(),
      provincialNumber: values.provincialNumber.toString(),
    }

    setSearchFilter(updatedValues)
  }

  const handleReset = (reset: any) => {
    setCurrentPage(1)
    setSearchFilter(null)
    reset()
  }

  const handleExport = () => {
    const token = localStorage.getItem('token') ?? ''
    dispatch(downloadInstitutionSheetRequest({ token }))
  }

  return (
    <Box sx={institutionsFilterStyles.box}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ resetForm, errors, touched, setFieldValue, values }) => {
          const touchedFields = touched as FormikTouched<any>
          const errorFields = errors as FormikErrors<any>

          return (
            <Form>
              <Box sx={institutionsFilterStyles.form}>
                {filterFields.map((e) => {
                  const targetElement = e
                  return (
                    <FormControl sx={institutionsFilterStyles.field} key={e.order}>
                      <FormLabel sx={institutionsFilterStyles.label} htmlFor={e.id}>
                        {e.name}
                      </FormLabel>
                      <Field
                        as={Input}
                        type='text'
                        maxLength={e.id === 'dni' ? 8 : 100}
                        name={e.id}
                        value={values[targetElement.id as keyof FilterFields]}
                        onBlur={() =>
                          setFieldValue(
                            `${targetElement.id}`,
                            `${values[targetElement.id as keyof FilterFields]}`.trim(),
                          )
                        }
                        onChange={(event: any) => {
                          e.id === 'institutionName'
                            ? setFieldValue(event.target.name, event.target.value)
                            : setFieldValue(event.target.name, event.target.value.replace(/ /g, ''))
                        }}
                      />
                      {errorFields[`${e.id}`] && touchedFields[`${e.id}`] && (
                        <Text color='red' fontSize={'sm'}>
                          {`${errorFields[e.id]}`}
                        </Text>
                      )}
                    </FormControl>
                  )
                })}
              </Box>
              <Box sx={institutionsFilterStyles.buttonsBox}>
                <Button sx={institutionsFilterStyles.searchButton} type='submit'>
                  Buscar
                </Button>
                <Button sx={institutionsFilterStyles.export} onClick={handleExport}>
                  Exportar{' '}
                </Button>
                <Button
                  sx={institutionsFilterStyles.cleanFilters}
                  onClick={() => handleReset(resetForm)}
                >
                  Limpiar filtros
                </Button>
              </Box>
            </Form>
          )
        }}
      </Formik>
    </Box>
  )
}

export default InstitutionsFilter
