import {
  Box,
  Button,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Switch,
  Text,
  useMediaQuery,
  useToast,
} from '@chakra-ui/react'
import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import {
  editUserEmailNotificationClean,
  editUserEmailNotificationRequest,
  userEmailNotificationClean,
  userEmailNotificationRequest,
} from '../../state/modules/notifications/actions'
import { useDispatch, useSelector } from 'react-redux'

import { notificationsModalStyles } from './styles'

interface NotificationsModalProps {
  isOpen: boolean
  onClose: () => void
}

interface Notification {
  type: string
  name: string
  title: string
  desciption: string
}

const notificationsTypes: Notification[] = [
  {
    type: 'ads',
    name: 'Avisos',
    title: 'Avisos interesantes para ti',
    desciption: 'Recibe por email los avisos que te podrían interesar',
  },
  {
    type: 'viewer',
    name: 'viewers',
    title: 'Notificaciones de visualización de perfil',
    desciption: 'Recibe un email semanal con las novedades de tu perfil',
  },
]

const NotificationsModal: FC<NotificationsModalProps> = ({ isOpen, onClose }) => {
  const emailSubscribed = useSelector(
    (state: any) => state?.notifications?.userEmailNotification?.userSettings?.emailSubscribed,
  )
  const viewerSubscribed = useSelector(
    (state: any) => state?.notifications?.userEmailNotification?.userSettings?.viewerSubscribed,
  )
  const { userEmailNotificationStatus, userEmailNotificationSuccess } = useSelector(
    (state: any) => state?.notifications,
  )
  const [isMobile] = useMediaQuery('(max-width: 600px)')
  const [isEmailEnable, setIsEmailEnable] = useState<boolean>(true)
  const [isViewerEnable, setIsViewerEnable] = useState<boolean>(true)
  const [notificationType, setNotificationType] = useState<any>({})
  const dispatch = useDispatch()
  const token = localStorage.getItem('access_token')
  const userId = useSelector((state: any) => state?.user?.userInformation?.id)
  const toast = useToast()
  const toastDuration = 2000

  useEffect(() => {
    isOpen && userId && dispatch(userEmailNotificationRequest({ token, id: userId }))

    return () => {
      isOpen && dispatch(userEmailNotificationClean())
    }
  }, [userId, isOpen])

  useEffect(() => {
    if (emailSubscribed !== undefined) {
      setIsEmailEnable(emailSubscribed)
    }
  }, [emailSubscribed])

  useEffect(() => {
    if (viewerSubscribed !== undefined) {
      setIsViewerEnable(viewerSubscribed)
    }
  }, [emailSubscribed])

  useEffect(() => {
    if (userEmailNotificationSuccess !== undefined && userEmailNotificationSuccess !== null) {
      toast({
        description:
          userEmailNotificationSuccess === false
            ? userEmailNotificationStatus?.response?.data?.msg
            : userEmailNotificationStatus?.message,
        status: userEmailNotificationSuccess === false ? 'error' : 'success',
        duration: toastDuration,
      })

      dispatch(editUserEmailNotificationClean())

      if (userEmailNotificationSuccess === true) {
        setTimeout(() => onClose(), 1000)
      }
    }
  }, [userEmailNotificationSuccess])

  const handleEmailNotifications = (
    e: ChangeEvent<HTMLInputElement>,
    notification: Notification,
  ) => {
    setNotificationType((prev: any) => {
      return { ...prev, [notification.type]: true }
    })
    if (notification.type === 'ads') {
      setIsEmailEnable((prev: boolean) => !prev)
      return
    }

    setIsViewerEnable((prev: boolean) => !prev)
  }

  const handleConfirm = () => {
    if (Object.values(notificationType).length === 0) {
      onClose()
    }

    if (notificationType?.ads || notificationType?.viewer) {
      dispatch(
        editUserEmailNotificationRequest({
          token,
          id: userId,
          emailSubscribed: isEmailEnable,
          viewerSubscribed: isViewerEnable,
        }),
      )
    }
  }

  return (
    <Modal scrollBehavior='inside' size={'2xl'} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent sx={notificationsModalStyles.modalContainer}>
        <ModalHeader sx={notificationsModalStyles.title}>Gestionar notificaciones</ModalHeader>
        <ModalCloseButton />
        <ModalBody
          padding={isMobile ? '4px 12px' : '8px 24px'}
          sx={notificationsModalStyles.modalBodyNotificationContainer}
        >
          {notificationsTypes.map((notification: Notification) => (
            <Box key={notification.type} sx={notificationsModalStyles.handleNotificationContainer}>
              <Box>
                <Heading sx={notificationsModalStyles.handleNotificationTitle}>
                  {notification.title}
                </Heading>
                <Text sx={notificationsModalStyles.handleNotificationText}>
                  {notification.desciption}
                </Text>
              </Box>
              <Box sx={notificationsModalStyles.switchContainer}>
                <Text sx={notificationsModalStyles.switchLabel}>Notificar</Text>

                <Switch
                  sx={notificationsModalStyles.switch}
                  isChecked={notification.type === 'ads' ? isEmailEnable : isViewerEnable}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleEmailNotifications(e, notification)
                  }
                  size='md'
                  colorScheme='green'
                />
              </Box>
            </Box>
          ))}
        </ModalBody>
        <ModalFooter>
          <Box sx={notificationsModalStyles.buttonsContainer}>
            <Button
              sx={
                isMobile
                  ? notificationsModalStyles.cancelButtonMobile
                  : notificationsModalStyles.cancelButton
              }
              _hover={isMobile ? {} : notificationsModalStyles.hover}
              onClick={onClose}
            >
              Cancelar
            </Button>
            <Button
              sx={
                isMobile
                  ? notificationsModalStyles.saveButtonMobile
                  : notificationsModalStyles.saveButton
              }
              _hover={isMobile ? {} : notificationsModalStyles.hover}
              onClick={handleConfirm}
            >
              Confirmar
            </Button>
          </Box>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default NotificationsModal
