import {
  Badge,
  Box,
  Icon,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
} from '@chakra-ui/react'
import {
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  CloseIcon,
  DeleteIcon,
  EditIcon,
  ViewIcon,
} from '@chakra-ui/icons'
import { JobDataField, JobsTableProps } from './_types'
import {
  duplicateJobRequest,
  editJobStatusRequest,
  jobDeleteRequest,
} from '../../../state/modules/ads/actions'
import { jobAdsFields, transformDate } from '../../../utils/jobAds'
import { useDispatch, useSelector } from 'react-redux'

import ActionPopover from '../../actionPopover'
import Loader from '../../loader'
import { MdContentCopy } from 'react-icons/md'
import Paginator from '../../Paginator'
import React from 'react'
import { colors } from '../../../utils/colors'
import { getRole } from '../../../utils/profile'
import { jobsTableStyles } from './styles'

const JobsTable: React.FC<JobsTableProps> = ({
  handleShow,
  setCurrentAd,
  currentPage,
  setCurrentPage,
  order,
  setOrder,
}) => {
  const jobsList = useSelector((state: any) => state?.ads?.jobsList?.rows)
  const totalElements = useSelector((state: any) => state?.ads?.jobsList?.count)
  const showedFields = jobAdsFields
    .filter((e: any) => e.isShown)
    .sort((e1: any, e2: any) => e1?.tableOrder - e2?.tableOrder)
  const userInformation = useSelector((state: any) => state?.user?.userInformation)
  const { isAIEPBA } = getRole(userInformation?.Institutions, userInformation?.Role?.id)
  const dispatch = useDispatch()

  const orderBy = (id: any, order: any) => {
    const field = id === 'creationDate' ? 'createdAt' : id === 'state' ? 'statusId' : id
    setOrder({ field: field, criteria: order })
  }
  const handleClosePopover = (id: string) => {
    const button = document.getElementById(`PopoverCloseButton${id}`)
    button?.click()
  }
  const handleDisable = (id: any) => {
    const token = localStorage.getItem('access_token') ?? ''
    dispatch(editJobStatusRequest({ token, adId: id, statusId: 2 }))
  }

  const handleCover = (id: any) => {
    const token = localStorage.getItem('access_token') ?? ''
    dispatch(editJobStatusRequest({ token, adId: id, statusId: 3 }))
  }

  const handleDelete = (id: any) => {
    const token = localStorage.getItem('access_token') ?? ''
    dispatch(jobDeleteRequest({ token, adId: id }))
  }

  const handleDuplicate = (id: any) => {
    const token = localStorage.getItem('access_token') ?? ''
    dispatch(duplicateJobRequest({ token, adId: id, creatorId: userInformation?.id }))
  }

  const adminInstitutions = userInformation?.Institutions?.filter(
    (e: any) => e?.Role?.id === '1',
  ).map((e: any) => {
    return e.cue
  })

  return (
    <Box sx={jobsTableStyles.container}>
      {!jobsList ? (
        <Loader props={jobsTableStyles.loading} />
      ) : jobsList.length === 0 ? (
        <Box sx={jobsTableStyles.noResultsContainer}>
          <Text sx={jobsTableStyles.noResults}>*No existen avisos para modificar</Text>
        </Box>
      ) : (
        <>
          <TableContainer>
            <Table colorScheme='facebook' sx={jobsTableStyles.table}>
              <Thead>
                <Tr>
                  {showedFields.map((e: JobDataField) => {
                    return (
                      <Th
                        minWidth={`${e.width}%`}
                        maxWidth={`${e.width}%`}
                        width={`${e.width}%`}
                        height={'100%'}
                        key={e.order}
                      >
                        <Box sx={jobsTableStyles.tableHead}>
                          {e.id !== 'institution' ? (
                            <Text
                              sx={jobsTableStyles.tableHeadText}
                              onClick={() => orderBy(e.id, 'DESC')}
                            >
                              {e.name}
                            </Text>
                          ) : (
                            <Text>{e.name}</Text>
                          )}
                          {order?.criteria === 'DESC' ? (
                            <Icon
                              as={ChevronDownIcon as React.ElementType}
                              visibility={
                                order?.field === e.id
                                  ? 'initial'
                                  : e.id === 'creationDate' && order?.field === 'createdAt'
                                  ? 'initial'
                                  : e.id === 'state' && order?.field === 'statusId'
                                  ? 'initial'
                                  : 'hidden'
                              }
                              boxSize={4}
                              sx={jobsTableStyles.chevronIcon}
                              onClick={() => orderBy(e.id, 'ASC')}
                            />
                          ) : (
                            <Icon
                              as={ChevronUpIcon as React.ElementType}
                              visibility={
                                order?.field === e.id
                                  ? 'initial'
                                  : e.id === 'creationDate' && order?.field === 'createdAt'
                                  ? 'initial'
                                  : e.id === 'state' && order?.field === 'statusId'
                                  ? 'initial'
                                  : 'hidden'
                              }
                              boxSize={4}
                              sx={jobsTableStyles.chevronIcon}
                              onClick={() => orderBy(e.id, 'DESC')}
                            />
                          )}
                        </Box>
                      </Th>
                    )
                  })}
                  <Th sx={jobsTableStyles.tableHeadAction}>Acción</Th>
                </Tr>
              </Thead>

              <Tbody>
                {jobsList.map((e: any) => {
                  const adminAdsInstitution: any[] = []
                  const publicationDate =
                    e.publicationDate && e.publicationDate !== null
                      ? transformDate(e.publicationDate)
                      : 'Sin fecha asignada'

                  const expirationDate =
                    e.expirationDate && e.expirationDate !== null
                      ? transformDate(e.expirationDate)
                      : 'Sin fecha asignada'

                  const creationDate =
                    e.createdAt && e.createdAt !== null
                      ? transformDate(e.createdAt)
                      : 'Sin fecha asignada'

                  const stateColor =
                    e.Status?.id === '5'
                      ? colors.states.draft
                      : e.Status?.id === '1'
                      ? colors.states.active
                      : e.Status?.id === '3'
                      ? colors.states.cover
                      : e.Status?.id === '4'
                      ? colors.states.expired
                      : colors.states.disabled

                  const instiCues = e.Institutions.map((e: any) => e.cue)

                  adminInstitutions?.forEach((adminInsti: any) => {
                    e.Institutions.some((elem: any) => elem.cue === adminInsti) &&
                      adminAdsInstitution?.push(adminInsti)
                  })

                  const canEdit = instiCues.length === adminAdsInstitution?.length ? true : false

                  return (
                    <Tr key={e.id}>
                      <Td sx={jobsTableStyles.tableData}>{e.referenceNumber ?? '-'}</Td>
                      <Td sx={jobsTableStyles.tableData}>
                        {e.Institutions?.map((e: any) => (
                          <Text key={e.id}>*{e.name}</Text>
                        ))}
                      </Td>
                      <Td sx={jobsTableStyles.tableData}>
                        <Badge sx={jobsTableStyles.badge} colorScheme={stateColor}>
                          {e.Status?.description ?? 'Sin Estado'}
                        </Badge>
                      </Td>
                      <Td sx={jobsTableStyles.tableData}>{creationDate}</Td>
                      <Td sx={jobsTableStyles.tableData} maxWidth={'200px'}>
                        <Text sx={jobsTableStyles.tableDataTitle}>
                          {e.title === '' ? 'Sin Título' : e.title}
                        </Text>
                      </Td>
                      <Td sx={jobsTableStyles.tableData}>{publicationDate}</Td>
                      <Td sx={jobsTableStyles.tableData}>{expirationDate}</Td>
                      <Td sx={jobsTableStyles.tableData}>
                        <Box sx={jobsTableStyles.actionBox}>
                          {(e.Status?.id === '1' ||
                            e.Status?.id === '2' ||
                            e.Status?.id === '3' ||
                            e.Status?.id === '4' ||
                            e.Status?.id === '5') && (
                            <Tooltip label='Ver' background={colors.principal}>
                              <Icon
                                as={ViewIcon as React.ElementType}
                                cursor='pointer'
                                boxSize={3}
                                color={'#2980B9'}
                                onClick={() => {
                                  handleShow('view')
                                  setCurrentAd(e)
                                }}
                              />
                            </Tooltip>
                          )}
                          {(isAIEPBA || canEdit) &&
                            (e.Status?.id === '1' || e.Status?.id === '5') && (
                              <Tooltip label='Editar' background={colors.principal}>
                                <Icon
                                  as={EditIcon as React.ElementType}
                                  cursor='pointer'
                                  boxSize={3}
                                  color={'#2980B9'}
                                  onClick={() => {
                                    handleShow('edit')
                                    setCurrentAd(e)
                                  }}
                                />
                              </Tooltip>
                            )}
                          {(isAIEPBA || canEdit) && (
                            <ActionPopover
                              handleAction={handleDuplicate}
                              id={`${e.id}`}
                              title={'¿Estás seguro de "Duplicar"?'}
                              handleClose={handleClosePopover}
                              buttonSize='12px'
                              closeId={`${e.id}_duplciate`}
                            >
                              <span>
                                <Icon
                                  as={MdContentCopy as React.ElementType}
                                  cursor='pointer'
                                  boxSize={3.5}
                                  color={'#2980B9'}
                                />
                              </span>
                            </ActionPopover>
                          )}
                          {(isAIEPBA || canEdit) && e.Status?.id === '5' && (
                            <ActionPopover
                              handleAction={handleDelete}
                              id={`${e.id}`}
                              title={'¿Estás seguro de "Eliminar"?'}
                              handleClose={handleClosePopover}
                              buttonSize='12px'
                              closeId={`${e.id}_delete`}
                            >
                              <Icon
                                as={DeleteIcon as React.ElementType}
                                cursor='pointer'
                                boxSize={3}
                                color={'#2980B9'}
                              />
                            </ActionPopover>
                          )}
                          {(isAIEPBA || canEdit) &&
                            (e.Status?.id === '1' || e.Status?.id === '5') && (
                              <ActionPopover
                                handleAction={handleDisable}
                                id={`${e.id}`}
                                title={'¿Estás seguro de "Desactivar"?'}
                                handleClose={handleClosePopover}
                                buttonSize='12px'
                                closeId={`${e.id}_disable`}
                              >
                                <Icon
                                  as={CloseIcon as React.ElementType}
                                  cursor='pointer'
                                  boxSize={2}
                                  color={'#2980B9'}
                                />
                              </ActionPopover>
                            )}
                          {(isAIEPBA || canEdit) && e.Status?.id === '1' && (
                            <ActionPopover
                              handleAction={handleCover}
                              id={`${e.id}`}
                              title={'¿Estás seguro de "Cubrir"?'}
                              handleClose={handleClosePopover}
                              buttonSize='12px'
                              closeId={`${e.id}_cover`}
                            >
                              <Icon
                                as={CheckIcon as React.ElementType}
                                cursor='pointer'
                                boxSize={3}
                                color={'#2980B9'}
                              />
                            </ActionPopover>
                          )}
                        </Box>
                      </Td>
                    </Tr>
                  )
                })}
              </Tbody>
            </Table>
          </TableContainer>
          <Paginator
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            elementsPerpage={10}
            elementsLength={totalElements}
            elements={jobsList}
            resultsDescription='Avisos'
          />
        </>
      )}
    </Box>
  )
}

export default JobsTable
