import { AddIcon, CheckIcon, CloseIcon, DeleteIcon, EditIcon } from '@chakra-ui/icons'
import { AssociatedUsersModalProps, AssociationData, AssociationnDataField } from './_types'
import {
  Badge,
  Box,
  Button,
  Select as ChakraSelect,
  Heading,
  Icon,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Thead,
  Tr,
  useToast,
} from '@chakra-ui/react'
import React, { FC, useEffect, useState } from 'react'
import {
  deleteUserAssociationClean,
  deleteUserAssociationRequest,
  editUserAssociationClean,
  editUserAssociationRequest,
  institutionAssociationsClean,
  institutionAssociationsRequest,
  newUserAssociationClean,
  rolesClean,
  rolesRequest,
} from '../../../../state/modules/abm/actions'
import { useDispatch, useSelector } from 'react-redux'

import Loader from '../../../loader'
import NewAssociationForm from './NewAssociationForm'
import { associatedUsersFields } from '../../../../utils/abm'
import { associatedUsersModalStyles } from './styles'
import { colors } from '../../../../utils/colors'

const AssociatedUsersModal: FC<AssociatedUsersModalProps> = ({ institution }) => {
  const currentUserId = useSelector((state: any) => state?.user?.userInformation?.id)
  const [showUserForm, setShowUserForm] = useState<boolean>(false)
  const [editedUser, setEditedUser] = useState<any>(null)

  const showedFields = associatedUsersFields
    .filter((e) => e.isShown)
    .sort((e1, e2) => e1.order - e2.order)
  const abm = useSelector((state: any) => state?.abm)
  const userRoles = useSelector((state: any) =>
    state?.abm?.rolesInformation?.filter((e: any) => e.type === 'institution'),
  )

  const dispatch = useDispatch()
  const toast = useToast()
  const toastDuration = 2000

  useEffect(() => {
    const token = localStorage.getItem('access_token') ?? ''

    dispatch(rolesRequest({ token }))
    return () => {
      dispatch(rolesClean())
    }
  }, [])

  useEffect(() => {
    const token = localStorage.getItem('access_token') ?? ''
    dispatch(institutionAssociationsRequest({ token, institutionId: institution.id }))

    return () => {
      dispatch(institutionAssociationsClean())
    }
  }, [
    institution.id,
    abm?.newUserAssociationSuccess === true,
    abm?.editUserAssociationSuccess === true,
    abm?.deleteUserAssociationSuccess === true,
  ])

  useEffect(() => {
    if (
      abm?.newUserAssociationSuccess !== null &&
      abm?.newUserAssociationSuccess !== undefined &&
      abm?.newUserAssociationSuccess === true
    ) {
      setShowUserForm(false)
      toast({
        description: abm?.newUserAssociationStatus?.message,
        status: 'success',
        duration: toastDuration,
        isClosable: true,
      })
      dispatch(newUserAssociationClean())
    }

    if (abm?.newUserAssociationSuccess === false) {
      toast({
        description: abm?.newUserAssociationStatus?.response?.data?.message,
        status: 'error',
        duration: toastDuration,
        isClosable: true,
      })

      dispatch(newUserAssociationClean())
    }
  }, [abm?.newUserAssociationSuccess])

  useEffect(() => {
    if (
      abm?.editUserAssociationSuccess !== null &&
      abm?.editUserAssociationSuccess !== undefined &&
      abm?.editUserAssociationSuccess === true
    ) {
      toast({
        description: abm?.editUserAssociationStatus?.message,
        status: 'success',
        duration: toastDuration,
        isClosable: true,
      })
      dispatch(editUserAssociationClean())
    }

    if (abm?.editUserAssociationSuccess === false) {
      toast({
        description: abm?.editUserAssociationStatus?.response?.data?.message,
        status: 'error',
        duration: toastDuration,
        isClosable: true,
      })

      dispatch(editUserAssociationClean())
    }
  }, [abm?.editUserAssociationSuccess])

  useEffect(() => {
    if (
      abm?.deleteUserAssociationSuccess !== null &&
      abm?.deleteUserAssociationSuccess !== undefined &&
      abm?.deleteUserAssociationSuccess === true
    ) {
      toast({
        description: abm?.deleteUserAssociationStatus?.message,
        status: 'success',
        duration: toastDuration,
        isClosable: true,
      })
      dispatch(deleteUserAssociationClean())
    }

    if (abm?.deleteUserAssociationSuccess === false) {
      toast({
        description: abm?.deleteUserAssociationStatus?.response?.data?.message,
        status: 'error',
        duration: toastDuration,
        isClosable: true,
      })

      dispatch(deleteUserAssociationClean())
    }
  }, [abm?.deleteUserAssociationSuccess])

  const handleShow = (name: string) => {
    if (name === 'addUser') {
      setShowUserForm(!showUserForm)
    }
  }
  const handleUserChange = (e: any) => {
    setEditedUser((prev: any) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      }
    })
  }

  const handleUserEdit = () => {
    const token = localStorage.getItem('access_token') ?? ''
    dispatch(
      editUserAssociationRequest({
        token,
        associationId: editedUser.id,
        roleId: editedUser.userRole,
      }),
    )
    setEditedUser(null)
  }

  const handleDeleteAssociatedUser = (id: string) => {
    const token = localStorage.getItem('access_token') ?? ''
    dispatch(deleteUserAssociationRequest({ token, targetId: id }))
  }

  return (
    <Box sx={associatedUsersModalStyles.box}>
      <Heading sx={associatedUsersModalStyles.heading}>{institution.name}</Heading>
      {!abm?.associationsInformation ? (
        <Loader props={associatedUsersModalStyles.loading} />
      ) : (
        <>
          <Box sx={associatedUsersModalStyles.tableSection}>
            <TableContainer>
              <Table sx={{}} colorScheme='facebook'>
                <Thead>
                  <Tr>
                    {showedFields.map((e: AssociationnDataField) => (
                      <Td key={e.order} sx={associatedUsersModalStyles.tableHead}>
                        {e.name}
                      </Td>
                    ))}
                    <Td sx={associatedUsersModalStyles.tableHead}>Acción</Td>
                  </Tr>
                </Thead>
                <Tbody>
                  {abm?.associationsInformation?.result?.map((e: AssociationData, i: number) => {
                    return (
                      <Tr key={i}>
                        <Td sx={associatedUsersModalStyles.tableData}>{e.User?.Profile?.name}</Td>
                        <Td sx={associatedUsersModalStyles.tableData}>
                          {e.User?.Profile?.lastName}
                        </Td>
                        <Td sx={associatedUsersModalStyles.tableData}>{e.User?.Profile?.dni}</Td>
                        <Td sx={associatedUsersModalStyles.tableData}>{e.User?.email}</Td>
                        <Td sx={associatedUsersModalStyles.tableData}>
                          {e.User?.Profile?.cellPhone || '-'}
                        </Td>
                        {editedUser?.id === e.id ? (
                          <Td>
                            <ChakraSelect
                              name={'userRole'}
                              sx={{ ...associatedUsersModalStyles.tableData, height: '25px' }}
                              onChange={handleUserChange}
                              value={editedUser?.userRole}
                            >
                              {userRoles?.map((e: any) => (
                                <option key={e.id} value={e.id}>
                                  {e.description}
                                </option>
                              ))}
                            </ChakraSelect>
                          </Td>
                        ) : (
                          <Td sx={associatedUsersModalStyles.tableData}>{e.Role?.description}</Td>
                        )}
                        <Td sx={associatedUsersModalStyles.tableData}>
                          {e.User?.verified === false ? (
                            <Badge sx={associatedUsersModalStyles.badge} colorScheme='yellow'>
                              Sin verificar
                            </Badge>
                          ) : e.User?.discardedAt === null ? (
                            <Badge sx={associatedUsersModalStyles.badge} colorScheme='green'>
                              Activo
                            </Badge>
                          ) : (
                            <Badge sx={associatedUsersModalStyles.badge} colorScheme='purple'>
                              Inactivo
                            </Badge>
                          )}
                        </Td>
                        <Td>
                          <Box sx={associatedUsersModalStyles.actionBox}>
                            {editedUser?.id === e.id ? (
                              <>
                                <Icon
                                  as={CheckIcon as React.ElementType}
                                  cursor='pointer'
                                  boxSize={3}
                                  color={colors.principal}
                                  onClick={handleUserEdit}
                                />
                                <Icon
                                  as={CloseIcon as React.ElementType}
                                  cursor='pointer'
                                  boxSize={2.5}
                                  color={colors.red}
                                  onClick={() => setEditedUser(null)}
                                />
                              </>
                            ) : (
                              <>
                                <Icon
                                  as={EditIcon as React.ElementType}
                                  cursor={currentUserId == e.userId ? 'not-allowed' : 'pointer'}
                                  boxSize={3}
                                  opacity={currentUserId == e.userId ? 0.5 : 1}
                                  color={colors.principal}
                                  onClick={() =>
                                    currentUserId != e.userId &&
                                    setEditedUser({ userRole: e.roleId, id: e.id })
                                  }
                                />
                                <Icon
                                  as={DeleteIcon as React.ElementType}
                                  cursor={currentUserId == e.userId ? 'not-allowed' : 'pointer'}
                                  boxSize={3}
                                  opacity={currentUserId == e.userId ? 0.5 : 1}
                                  color={colors.red}
                                  onClick={() =>
                                    currentUserId != e.userId && handleDeleteAssociatedUser(e.id)
                                  }
                                />
                              </>
                            )}
                          </Box>
                        </Td>
                      </Tr>
                    )
                  })}
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
          <Box
            sx={{
              display: 'flex',
              width: '50%',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: '24px',
            }}
          >
            {showUserForm && (
              <NewAssociationForm
                setShowUserForm={setShowUserForm}
                roles={abm?.rolesInformation?.filter((e: any) => e.type === 'institution')}
                institutionId={institution.id}
              />
            )}
            <Button
              mt={4}
              width='70%'
              color='white'
              name='addUser'
              isDisabled={showUserForm}
              backgroundColor={colors.principal}
              onClick={(e) => handleShow(e.currentTarget.name)}
            >
              <Text>Agregar usuario</Text>
              <AddIcon boxSize={4} ml={2} />
            </Button>
          </Box>
        </>
      )}
    </Box>
  )
}

export default AssociatedUsersModal
