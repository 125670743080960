import * as Yup from 'yup'

import {
  Box,
  Button,
  Select as ChakraSelect,
  Checkbox,
  FormControl,
  FormLabel,
  Heading,
  Img,
  Input,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import { Field, Form, Formik, FormikErrors, FormikTouched, FormikValues } from 'formik'
import { FormValues, InstitutionModalProps, RoleInformation } from './_types'
import React, { ChangeEvent, ChangeEventHandler, FC, useEffect, useRef, useState } from 'react'
import Select, { components } from 'react-select'
import {
  departmentsClean,
  departmentsRequest,
  employeesQuantityClean,
  employeesQuantityRequest,
  institutionTypesClean,
  institutionTypesRequest,
  institutionalDataRequest,
  levelmodalitiesClean,
  levelmodalitiesRequest,
  levelsClean,
  levelsRequest,
  localitiesClean,
  localitiesRequest,
  provincesClean,
  provincesRequest,
  regionsClean,
  regionsRequest,
  studentsQuantityClean,
  studentsQuantityRequest,
} from '../../../../state/modules/user/actions'
import {
  findUsersClean,
  findUsersRequest,
  newInstitutionRequest,
  rolesClean,
  rolesRequest,
  updateInstitutionDataRequest,
} from '../../../../state/modules/abm/actions'
import { useDebounce, useDebouncedCallback } from 'use-debounce'
import { useDispatch, useSelector } from 'react-redux'

import AsyncSelect from 'react-select/async'
import EmptyInstitution from '../../../../assets/images/profile/InsitutionEmpty.png'
import { QuestionIcon } from '@chakra-ui/icons'
import { colors } from '../../../../utils/colors'
import { getUsersService } from '../../../../state/modules/abm/services'
import { institutionFields } from '../../../../utils/abm'
import { institutionModalStyles } from './styles'
import { EmailRegex } from '../../../../utils/regexTypes'

const InstitutionModal: FC<InstitutionModalProps> = ({
  action,
  currentInstitution,
  isLoading,
  setIsLoading,
}) => {
  const abm = useSelector((state: any) => state?.abm)
  const user = useSelector((state: any) => state?.user)
  const state = useSelector((state: any) => state)

  const filePhotoInputRef = useRef<HTMLInputElement>(null)
  const institutionSection =
    action === 'add'
      ? institutionFields.filter((field: any) => field.section === 'institution' && field.isAdded)
      : institutionFields.filter((field: any) => field.section === 'institution' && field.isEdited)
  const userSection = institutionFields.filter(
    (field: any) => field.section === 'user' && field.isAdded,
  )
  const [displayedImg, setDisplayedImg] = useState<string | null>(null)
  const [uploadImg, setUploadImg] = useState<File | null>(null)
  const [notAllow, setNotAllow] = useState<boolean>(false)
  const [inProcess, setInProcess] = useState<boolean>(false)
  const [selectedProvince, setSelectedProvince] = useState<{
    value?: string
    label?: string
  } | null>(
    currentInstitution?.Address?.Province?.id
      ? {
          value: currentInstitution?.Address?.Province?.id.toString(),
          label: currentInstitution?.Address?.Province?.description,
        }
      : null,
  )
  const [selectedDepartment, setSelectedDepartment] = useState<{
    value?: string
    label?: string
  } | null>(
    currentInstitution?.Address?.Department?.id
      ? {
          value: currentInstitution?.Address?.Department?.id.toString(),
          label: currentInstitution?.Address?.Department?.description,
        }
      : null,
  )
  const [selectedLocality, setSelectedLocality] = useState<{
    value?: string
    label?: string
  } | null>(
    currentInstitution?.Address?.Locality?.id
      ? {
          value: currentInstitution?.Address?.Locality?.id.toString(),
          label: currentInstitution?.Address?.Locality?.description,
        }
      : null,
  )

  const [selectedRegion, setSelectedRegion] = useState<{
    value?: string
    label?: string
  } | null>(
    currentInstitution?.Region?.id
      ? {
          value: currentInstitution?.Region?.id.toString(),
          label: currentInstitution?.Region?.description,
        }
      : null,
  )

  const dispatch = useDispatch()

  const [levels, setLevels] = useState<any>(
    currentInstitution?.Level?.id
      ? { value: currentInstitution?.Level?.id, label: currentInstitution?.Level?.description }
      : null,
  )

  const [levelModalitiesOptions, setLevelModalitiesOptions] = useState<any[]>([])
  const [levelModalities, setLevelModalities] = useState<any[]>(
    currentInstitution?.LevelModalities?.length > 0
      ? currentInstitution?.LevelModalities?.map((e: any) => {
          return { value: e.Modality.id, label: e.Modality.description }
        })
      : [],
  )
  const [userId, setUserId] = useState<string | null>(null)

  const usersMessage = 'Solo se mostrarán los usuarios que tienen todos los datos cargados'

  useEffect(() => {
    const token = localStorage.getItem('access_token') ?? ''
    if (action === 'add') dispatch(rolesRequest({ token }))

    if (action === 'edit') {
      dispatch(levelsRequest({ token }))
      dispatch(levelmodalitiesRequest({ token }))
      dispatch(institutionTypesRequest({ token }))
      dispatch(provincesRequest({ token }))
      dispatch(employeesQuantityRequest({ token }))
      dispatch(studentsQuantityRequest({ token }))
    }
    return () => {
      if (action === 'add') dispatch(rolesClean())
      if (action === 'edit') {
        dispatch(levelsClean())
        dispatch(levelmodalitiesClean())
        dispatch(institutionTypesClean())
        dispatch(provincesClean())
        dispatch(employeesQuantityClean())
        dispatch(studentsQuantityClean())
      }
    }
  }, [action])

  useEffect(() => {
    const token = localStorage.getItem('access_token') ?? ''
    if (selectedProvince?.value)
      dispatch(departmentsRequest({ token, provinceId: selectedProvince.value }))

    return () => {
      dispatch(departmentsClean())
    }
  }, [selectedProvince])

  useEffect(() => {
    const token = localStorage.getItem('access_token') ?? ''
    if (selectedDepartment?.value)
      dispatch(
        localitiesRequest({
          token,
          departmentId: selectedDepartment.value,
        }),
      )
    return () => {
      dispatch(localitiesClean())
    }
  }, [selectedDepartment])

  useEffect(() => {
    const token = localStorage.getItem('access_token') ?? ''
    if (selectedProvince?.value === '1') dispatch(regionsRequest({ token, provinceId: '1' }))
    return () => {
      dispatch(departmentsClean())
    }
  }, [selectedProvince])

  const initialAddValues = {
    cue: '',
    provincialNumber: '',
    institutionName: '',
    inProcess: false,
    email: '',
    userEmail: '',
    userName: '',
    userDNI: '',
    userCellphone: '',
    userRole: '1',
  }

  const initialEditValues = {
    cue: currentInstitution?.cue?.toString() ?? '',
    provincialNumber: currentInstitution?.provincialNumber ?? '',
    institutionName: currentInstitution?.name ?? '',
    email: currentInstitution?.email ?? '',
    provinceId: currentInstitution?.Address?.Province?.id ?? '',
    departmentId: currentInstitution?.Address?.Department?.id ?? '',
    localityId: currentInstitution?.Address?.Locality?.id ?? '',
    regionId: currentInstitution?.Region?.id ?? '',
    level: currentInstitution?.Level?.id ?? '',
    levelModalities: currentInstitution?.LevelModalities?.map((e: any) => e.Modality.id) ?? [],
    institutionTypes: currentInstitution?.InstitutionType?.id ?? '',
    studentsQuantity: currentInstitution?.StudentsQuantity?.id ?? '',
    employeesQuantity: currentInstitution?.EmployeesQuantity?.id ?? '',
  }

  const getUsers = useDebouncedCallback(
    ({ query }: { query: string }) => {
      const token = localStorage.getItem('access_token') ?? ''
      return getUsersService({ token, query })
        .then((res) => {
          return res.data.users.map((e: any) => ({
            value: e.id,
            label: e.email,
            profile: { ...e.Profile },
          }))
        })
        .catch((error) => console.log(error))
    },
    300,
    { leading: true },
  )

  const handleInputEmailChange = (InputValue: string) => {
    if (InputValue?.length > 2) {
      return new Promise<any>((resolve) => {
        setTimeout(() => {
          resolve(getUsers({ query: InputValue }))
        }, 1000)
      })
    } else
      return new Promise<any>((resolve) => {
        setTimeout(() => {
          resolve([])
        }, 1000)
      })
  }

  const NoOptionsMessage = (props: any) => {
    return (
      <components.NoOptionsMessage {...props}>
        <span>
          {props?.selectProps?.inputValue?.length > 2
            ? 'No existen resultados'
            : 'Se debe ingresar más de 2 caracteres'}
        </span>
      </components.NoOptionsMessage>
    )
  }

  const renderInput = (
    id: string,
    setFieldValue?: any,
    handleChange?: any,
    setFieldTouched?: any,
    values?: any,
    setValues?: any,
  ) => {
    switch (true) {
      case id === 'userRole':
        return (
          <Select
            name={id}
            options={abm?.rolesInformation
              ?.filter((role: RoleInformation) => role.type !== 'global')
              ?.map((e: RoleInformation, i: any) => ({
                value: e.id,
                label: e.description,
              }))}
            defaultValue={{ label: 'Administrador', value: '1' }}
            isDisabled
            styles={{
              container: (base: any) => ({
                ...base,
                opacity: 0.5,
              }),
            }}
          />
        )
      case id === 'inProcess':
        return (
          <Box>
            <Checkbox
              name={id}
              onChange={(e: any) => handleInProcessChange(e, handleChange, setFieldValue)}
              height={'40px'}
            />
          </Box>
        )
      case id === 'userEmail':
        return (
          <AsyncSelect
            isClearable
            loadOptions={handleInputEmailChange}
            onChange={(e) => handleEmailChange(e, setValues)}
            onFocus={() => setFieldTouched(id, true)}
            placeholder='Elije el usuario asociado'
            loadingMessage={() => 'Cargando...'}
            components={{ NoOptionsMessage }}
          />
        )
      case id === 'cue' || id === 'provincialNumber':
        return (
          <Field
            as={Input}
            name={id}
            isDisabled={inProcess}
            background={inProcess ? '#f2f2f2' : 'inherit'}
            value={values[`${id}`]}
            onChange={(e: any) => handleNumberChange(e, handleChange)}
          />
        )
      case id === 'userDNI' || id === 'userCellphone':
        return (
          <Field
            as={Input}
            name={id}
            onChange={(e: any) => handleNumberChange(e, handleChange)}
            isDisabled={true}
            _disabled={{ background: '#f2f2f2', opacity: 0.5 }}
          />
        )
      case id === 'level':
        return (
          <Select
            name={id}
            options={user?.levelsList?.map((e: any) => ({
              value: e.id,
              label: e.description,
            }))}
            value={levels}
            placeholder='Elija un nivel'
            onChange={(e: any) => {
              handleLevelsChange(e, setFieldValue)
            }}
          />
        )
      case id === 'levelModalities':
        return (
          <Select
            isMulti
            name={id}
            options={levelModalitiesOptions}
            placeholder='Elija una característica'
            value={levelModalities}
            onChange={(e: any) => {
              handleLevelModalitiesChange(e, setFieldValue)
            }}
          />
        )
      case id === 'institutionTypes':
        return (
          <Select
            name={id}
            placeholder='Elija una modalidad'
            defaultValue={
              currentInstitution?.InstitutionType?.id
                ? {
                    value: currentInstitution?.InstitutionType?.id,
                    label: currentInstitution?.InstitutionType?.description,
                  }
                : null
            }
            options={user?.institutionTypesList?.map((e: any) => ({
              value: e.id,
              label: e.description,
            }))}
            onChange={(e: any) => {
              handleInstitutionTypesChange(e, setFieldValue)
            }}
          />
        )
      case id === 'studentsQuantity':
        return (
          <Select
            name={id}
            placeholder='Elija una cantidad'
            defaultValue={
              currentInstitution?.StudentsQuantity?.id
                ? {
                    value: currentInstitution?.StudentsQuantity?.id,
                    label: currentInstitution?.StudentsQuantity?.description,
                  }
                : null
            }
            options={user?.studentsQuantityList?.map((e: any) => ({
              value: e.id,
              label: e.description,
            }))}
            onChange={(e: any) => {
              handleStudentsQuantityChange(e, setFieldValue)
            }}
          />
        )
      case id === 'employeesQuantity':
        return (
          <Select
            name={id}
            placeholder='Elija una cantidad'
            defaultValue={
              currentInstitution?.EmployeesQuantity?.id
                ? {
                    value: currentInstitution?.EmployeesQuantity?.id,
                    label: currentInstitution?.EmployeesQuantity?.description,
                  }
                : null
            }
            options={user?.employeesQuantityList?.map((e: any) => ({
              value: e.id,
              label: e.description,
            }))}
            onChange={(e: any) => {
              handleEmployeesQuantityChange(e, setFieldValue)
            }}
          />
        )
      case id === 'provinceId':
        return (
          <Select
            name={id}
            styles={{
              menuList: (base) => ({
                ...base,
                maxHeight: '180px',
              }),
            }}
            placeholder='Elija una provincia'
            options={user?.provincesList?.map((e: any) => ({ value: e.id, label: e.description }))}
            value={selectedProvince}
            onChange={(e: any) => {
              handleProvinceChange(e, setFieldValue)
            }}
          />
        )
      case id === 'departmentId':
        return (
          <Select
            name={id}
            styles={{
              menuList: (base) => ({
                ...base,
                maxHeight: '180px',
              }),
            }}
            placeholder='Elija un departemento/partido'
            options={user?.departmentsList?.map((e: any) => ({
              value: e.id,
              label: e.description,
            }))}
            value={selectedDepartment}
            onChange={(e: any) => {
              handleDepartmentChange(e, setFieldValue)
            }}
          />
        )
      case id === 'localityId':
        return (
          <Select
            name={id}
            styles={{
              menuList: (base) => ({
                ...base,
                maxHeight: '180px',
              }),
            }}
            placeholder='Elija una localidad'
            options={user?.localitiesList?.map((e: any) => ({
              value: e.id,
              label: e.description,
            }))}
            value={selectedLocality}
            onChange={(e: any) => {
              handleLocalityChange(e, setFieldValue)
            }}
          />
        )
      case id === 'regionId':
        return (
          <Select
            name={id}
            styles={{
              menuList: (base) => ({
                ...base,
                maxHeight: '100px',
              }),
            }}
            options={user?.regionsList?.map((e: any) => ({ value: e.id, label: e.description }))}
            value={selectedRegion}
            onChange={(e: any) => {
              handleRegionChange(e, setFieldValue)
            }}
          />
        )
      default:
        return (
          <Field
            as={Input}
            type='text'
            name={id}
            maxLength={'50'}
            isDisabled={id === 'userName' ? true : false}
            _disabled={{
              background: id === 'userName' ? '#f2f2f2' : 'inherit',
              opacity: id === 'userName' ? 0.5 : 1,
            }}
          />
        )
    }
  }

  const handleButtonClickPhoto = () => {
    if (filePhotoInputRef.current) {
      filePhotoInputRef.current.click()
    }
  }
  const handlePhotoChange = (file: File) => {
    const maxSize = 10 * 1024 * 1024
    if (file) {
      if (
        (file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/jpeg') &&
        file.size <= maxSize
      ) {
        const img = URL.createObjectURL(file)
        setDisplayedImg(img)
        setUploadImg(file)
        setNotAllow(false)
      } else {
        setNotAllow(true)
        setDisplayedImg(null)
        setUploadImg(null)
      }
    }
  }

  const handleInProcessChange = (e: any, handleChange: any, setFieldValue: any) => {
    handleChange(e)
    setInProcess((prev: boolean) => !prev)
    setFieldValue('cue', '')
    setTimeout(() => {
      setFieldValue('provincialNumber', '')
    }, 100)
  }
  const handleNumberChange = (e: any, handleChange: any) => {
    const number = Number(e.nativeEvent.data)

    if (number || e.nativeEvent.data == null || e.nativeEvent.data === '0') handleChange(e)
  }

  const handleProvinceChange = (e: any, setFieldValue: any) => {
    const selectedProvinceId = e.value
    e.value = e.value.toString()
    setSelectedProvince(e)
    setFieldValue('provinceId', selectedProvinceId)
    setSelectedDepartment(null)
    setTimeout(() => setFieldValue('departmentId', ''), 10)
    setSelectedLocality(null)
    setTimeout(() => setFieldValue('localityId', ''), 20)
    setSelectedRegion(null)
    setTimeout(() => setFieldValue('regionId', ''), 30)
  }

  const handleDepartmentChange = (e: any, setFieldValue: any) => {
    const selectedDepartmentId = e.value
    e.value = e.value.toString()
    setSelectedDepartment(e)
    setFieldValue('departmentId', selectedDepartmentId)
    setSelectedLocality(null)
    setTimeout(() => setFieldValue('localityId', ''), 10)
  }

  const handleLocalityChange = (e: any, setFieldValue: any) => {
    const selectedLocalityId = e.value
    e.value = e.value.toString()

    setSelectedLocality(e)
    setFieldValue('localityId', selectedLocalityId)
  }

  const handleRegionChange = (e: any, setFieldValue: any) => {
    const selectedRegionId = e.value
    e.value = e.value.toString()
    setSelectedRegion(e)
    setFieldValue('regionId', selectedRegionId)
  }

  const handleInstitutionTypesChange = (e: any, setFieldValue: any) => {
    setFieldValue('institutionTypes', e.value)
  }

  const handleStudentsQuantityChange = (e: any, setFieldValue: any) => {
    setFieldValue('studentsQuantity', e.value)
  }

  const handleEmployeesQuantityChange = (e: any, setFieldValue: any) => {
    setFieldValue('employeesQuantity', e.value)
  }

  const handleEmailChange = (e: any, setValues: any) => {
    if (e !== null) {
      setUserId(e.value)
      setValues((values: any) => {
        return {
          ...values,
          userEmail: e.profile.email,
          userName: `${e.profile.name} ${e.profile.lastName}`,
          userDNI: e.profile.dni,
          userCellphone: e.profile.cellPhone,
        }
      })
    } else {
      setUserId(null)
      setValues((values: any) => {
        return {
          ...values,
          userEmail: '',
          userName: '',
          userDNI: '',
          userCellphone: '',
        }
      })
    }
  }

  const handleLevelsChange = (e: any, setFieldValue: any) => {
    setFieldValue('level', e.value)
    setLevelModalities([])
    setTimeout(() => setFieldValue('levelModalities', []), 10)
    setLevels(e)
  }

  useEffect(() => {
    setLevelModalitiesOptions(
      user?.levelModalitiesList
        ?.filter((e: any) => e.levelid.toString() === levels?.value)
        .map((e: any) => ({
          value: e.levelmodalityid,
          label: e.description,
        })),
    )
  }, [user, levels])

  const handleLevelModalitiesChange = (e: any, setFieldValue: any) => {
    setLevelModalities(e)

    setFieldValue(
      'levelModalities',
      e.map((e: any) => e.value),
    )
  }

  const addSchemaValidation = Yup.object({
    cue: inProcess
      ? Yup.number()
      : Yup.number()
          .typeError('Este campo solo acepta números')
          .max(999999999999999, 'Maximo 15 caracteres')
          .required(() => (inProcess ? false : 'Este campo es requerido')),
    provincialNumber: inProcess
      ? Yup.number()
      : Yup.number()
          .typeError('Este campo solo acepta números')
          .max(9999999999, 'Maximo 10 caracteres')
          .required('Este campo es requerido'),
    institutionName: Yup.string().required('Este campo es requerido'),
    email: Yup.string()
      .email('Email no válido')
      .matches(EmailRegex, 'Email no válido')
      .required('Ingrese un email'),
    inProcess: Yup.boolean(),
    userEmail: Yup.string().required('Este campo es requerido'),
    userName: Yup.string(),
    userDNI: Yup.string(),
    userCellphone: Yup.string(),
    userRole: Yup.string(),
  }).defined()

  const editSchemaValidation = Yup.object({
    email: Yup.string()
      .email('Email no válido')
      .matches(EmailRegex, 'Email no válido')
      .required('Ingrese un email'),
    cue: Yup.number()
      .typeError('Este campo solo acepta números')
      .max(999999999999999, 'Maximo 15 caracteres')
      .required(() => (inProcess ? false : 'Este campo es requerido')),
    provincialNumber: Yup.number()
      .typeError('Este campo solo acepta números')
      .max(9999999999, 'Maximo 10 caracteres')
      .required('Este campo es requerido'),
    institutionName: Yup.string().required('Este campo es requerido'),
    level: Yup.string().required('Este campo es requerido'),
    levelModalities: Yup.array(),
    institutionTypes: Yup.string().required('Este campo es requerido'),
    studentsQuantity: Yup.string().required('Este campo es requerido'),
    employeesQuantity: Yup.string().required('Este campo es requerido'),
    provinceId: Yup.string().required('Este campo es requerido'),
    departmentId: Yup.string().required('Este campo es requerido'),
    localityId: Yup.string().required('Este campo es requerido'),
    regionId:
      selectedProvince?.value === '1'
        ? Yup.string().required('Este campo es requerido')
        : Yup.string(),
  }).defined()

  const handleSubmit = (values: any) => {
    const token = localStorage.getItem('access_token') ?? ''

    const updatedValues =
      action === 'add'
        ? {
            name: values.institutionName,
            cue: values.cue,
            provincialNumber: values.provincialNumber,
            email: values.email,
            inProcess: values.inProcess,
            userId: userId,
            roleId: values.userRole,
          }
        : {
            institutionId: Number(currentInstitution?.id),
            cue: values.cue,
            name: values.institutionName,
            email: values.email,
            provincialNumber: values.provincialNumber,
            employeesQuantity: Number(values.employeesQuantity),
            studentsQuantity: Number(values.studentsQuantity),
            Address: {
              id: currentInstitution?.Address?.id,
              provinceId: values.provinceId,
              departmentId: values.departmentId,
              localityId: values.localityId,
            },
            region: values.regionId === '' ? null : values.regionId,
            levelId: values.level,
            modality: values.levelModalities?.map((e: any) => {
              return { ['levelModalityId']: e }
            }),
            institutionTypeId: values.institutionTypes,
          }
    setIsLoading(true)

    action === 'add'
      ? dispatch(newInstitutionRequest({ token, institution: updatedValues, photoUp: uploadImg }))
      : dispatch(
          updateInstitutionDataRequest({
            token,
            data: updatedValues,
            photoUp: uploadImg,
          }),
        )
  }

  return (
    <Box sx={institutionModalStyles.box}>
      <Heading sx={institutionModalStyles.heading}>
        {action === 'add' ? 'Alta de institución' : 'Editar información de la institución'}
      </Heading>
      <Formik
        initialValues={action === 'add' ? initialAddValues : initialEditValues}
        onSubmit={handleSubmit}
        validationSchema={action === 'add' ? addSchemaValidation : editSchemaValidation}
      >
        {({ values, errors, setFieldValue, handleChange, setFieldTouched, touched, setValues }) => {
          const touchedFields = touched as FormikTouched<any>
          const errorFields = errors as FormikErrors<any>
          const formValues = values as FormValues
          return (
            <Form>
              <Box sx={institutionModalStyles.imgPreviewContainer}>
                <Img
                  src={
                    displayedImg !== null
                      ? displayedImg
                      : action !== 'add' && currentInstitution?.UserFiles?.length > 0
                      ? currentInstitution.UserFiles[0]?.filePath
                      : EmptyInstitution
                  }
                  alt='logo'
                  sx={institutionModalStyles.imgPreview}
                />

                <Text sx={institutionModalStyles.imgText}>
                  Tamaño maximo 10 MB - Formato permitido: .png, .jpg, .jpeg
                </Text>
                {notAllow ? (
                  <Text sx={institutionModalStyles.notAllowText}>Archivo no permitido</Text>
                ) : (
                  <Text sx={institutionModalStyles.imgNameText}>
                    {uploadImg?.name ?? 'Foto de institución'}
                  </Text>
                )}
                <Button
                  sx={institutionModalStyles.fileButton}
                  backgroundColor='#385898'
                  color='white'
                  _hover={{ bg: '#2d4373' }}
                  _active={{ bg: '#23345a' }}
                  onClick={handleButtonClickPhoto}
                >
                  Seleccionar foto
                </Button>
                <Input
                  type='file'
                  ref={filePhotoInputRef}
                  style={{ display: 'none' }}
                  onChange={(event: any) => handlePhotoChange(event.target.files[0])}
                  accept='.png, .jpeg, .jpg'
                />
              </Box>

              <Box sx={institutionModalStyles.item}>
                <Heading as='h2' sx={institutionModalStyles.itemTitle}>
                  Datos de la institución
                </Heading>
                <Box sx={institutionModalStyles.inputContainer}>
                  {institutionSection.map((e: any) => {
                    return e.id === 'regionId' ? (
                      selectedProvince?.value === '1' && (
                        <FormControl
                          key={e.order}
                          sx={
                            e.id === 'inProcess'
                              ? {
                                  ...institutionModalStyles.input,
                                  display: 'flex',
                                  alignItems: 'center',
                                }
                              : institutionModalStyles.input
                          }
                        >
                          <FormLabel>
                            {e.name}
                            {e.id !== 'levelModalities' && '*'}
                          </FormLabel>
                          {renderInput(e.id, setFieldValue, handleChange, setFieldTouched, values)}
                          {errorFields[`${e.id}`] && touchedFields[`${e.id}`] && (
                            <Text color='red' fontSize={'sm'}>
                              {`${errorFields[e.id]}`}
                            </Text>
                          )}
                        </FormControl>
                      )
                    ) : (
                      <FormControl
                        key={e.order}
                        sx={
                          e.id === 'inProcess'
                            ? {
                                ...institutionModalStyles.input,
                                display: 'flex',
                                alignItems: 'center',
                                width: '20%',
                              }
                            : institutionModalStyles.input
                        }
                      >
                        <FormLabel>
                          {e.name}
                          {e.id !== 'levelModalities' && '*'}
                        </FormLabel>
                        {renderInput(e.id, setFieldValue, handleChange, setFieldTouched, values)}
                        {errorFields[`${e.id}`] && touchedFields[`${e.id}`] && (
                          <Text color='red' fontSize={'sm'}>
                            {`${errorFields[e.id]}`}
                          </Text>
                        )}
                      </FormControl>
                    )
                  })}
                  {action === 'add' && formValues?.inProcess === true && (
                    <Text sx={institutionModalStyles.inProcessText}>
                      *CUE y Nro. Provincial serán datos provisorios generados automáticamente por
                      EDUprivada
                    </Text>
                  )}
                </Box>
              </Box>
              {action === 'add' && (
                <Box sx={institutionModalStyles.item}>
                  <Heading as='h2' sx={institutionModalStyles.itemTitle}>
                    Datos del individuo
                  </Heading>
                  <Box sx={institutionModalStyles.inputContainer}>
                    {userSection.map((e: any) => (
                      <FormControl key={e.order} sx={institutionModalStyles.input}>
                        <FormLabel>
                          {e.name}*{' '}
                          {e.id === 'userEmail' && (
                            <Tooltip label={usersMessage}>
                              <QuestionIcon />
                            </Tooltip>
                          )}
                        </FormLabel>
                        {renderInput(
                          e.id,
                          setFieldValue,
                          handleChange,
                          setFieldTouched,
                          values,
                          setValues,
                        )}
                        {errorFields[`${e.id}`] && touchedFields[`${e.id}`] && (
                          <Text color='red' fontSize={'sm'}>
                            {`${errorFields[e.id]}`}
                          </Text>
                        )}
                      </FormControl>
                    ))}
                  </Box>
                </Box>
              )}
              <Box sx={institutionModalStyles.buttonBox}>
                <Button
                  sx={institutionModalStyles.saveButton}
                  _hover={institutionModalStyles.saveButtonHover}
                  type='submit'
                  isLoading={isLoading}
                >
                  Confirmar
                </Button>
              </Box>
            </Form>
          )
        }}
      </Formik>
    </Box>
  )
}

export default InstitutionModal
