import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  Image,
  BreadcrumbLink,
  Card,
  CardBody,
  CardHeader,
  Divider,
  Heading,
  HStack,
  Img,
  Text,
  useMediaQuery,
} from '@chakra-ui/react'
import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import InstitutionContactData from './InstitutionContactData'
import InstitutionOwnerData from './InstitutionOwnerData'
import InstitutionProfileData from './InstitutionProfileData'
import { InstitutionProfileProps } from './_types'
import Loader from '../loader'
import { Navigate } from 'react-router'
import { getInstitutionRequest } from '../../state/modules/profiles/actions'
import institutionEmpty from '../../assets/images/profile/InsitutionEmpty.png'
import { institutionProfileStyles } from './styles'
import { useParams, Link as RouterLink } from 'react-router-dom'
import IconInstagram from '../../assets/images/icons/icon_instagram.svg'
import IconFacebook from '../../assets/images/icons/icon_facebook.svg'
import IconOfficialWeb from '../../assets/images/icons/official_web.svg'
import IconLinkedin from '../../assets/images/icons/icon_linkedin.svg'
import IconTelegram from '../../assets/images/icons/icon_telegram.svg'
import { colors } from '../../utils/colors'

const InstitutionProfile: FC<InstitutionProfileProps> = () => {
  const [isMobile] = useMediaQuery('(max-width: 600px)')
  const institution = useSelector((state: any) => state?.profiles?.institutionInformation)
  const { institutionId } = useParams()
  const dispatch = useDispatch()

  useEffect(() => {
    const token = localStorage.getItem('access_token') ?? ''
    institutionId && dispatch(getInstitutionRequest({ institutionId, token }))
  }, [institutionId])

  return (
    <>
      {institution === false ? (
        <Navigate to='/home' />
      ) : institution ? (
        <Box id='adDetail' sx={institutionProfileStyles.container}>
          {/* BLUE STRIPE */}
          <Box
            sx={
              isMobile
                ? institutionProfileStyles.topStripeMobile
                : institutionProfileStyles.topStripe
            }
          >
            <Box position='relative' pl={['40px', '68px', '68px']}>
              <Box position='absolute' top={['6px', '10px', '8px']}>
                <Breadcrumb
                  color='#fff'
                  fontSize={['13px', '14px', '19px']}
                  fontWeight={100}
                  fontFamily={'Roboto-Flex'}
                >
                  <BreadcrumbItem>
                    <BreadcrumbLink href='/home'>Avisos</BreadcrumbLink>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    <BreadcrumbLink href='#'>Institución</BreadcrumbLink>
                  </BreadcrumbItem>
                </Breadcrumb>
              </Box>

              {/* AVATAR IMG AND BREADCRUM CONTAINER */}
              <Box
                sx={
                  isMobile
                    ? institutionProfileStyles.avatarContainerMobile
                    : institutionProfileStyles.avatarContainer
                }
                boxShadow='10px 10px 40px 0.5px rgb(0 0 0 / 10%)'
                position='absolute'
                top={['42px', '62px', '62px']}
              >
                <Img
                  sx={
                    isMobile
                      ? institutionProfileStyles.avatarMobile
                      : institutionProfileStyles.avatarImage
                  }
                  alt={institution?.name}
                  src={institution?.UserFiles[0]?.filePath ?? institutionEmpty}
                />
              </Box>
            </Box>
          </Box>

          {/* TITLE, INSITUTION NAME AND LOCATION CONTAINER */}
          <Box
            display='flex'
            flexDirection={['column']}
            p={['15px 0 0 40px', '15px 0 0 68px', '15px 0 0 68px']}
          >
            <Box w={['100%']}>
              <Text
                sx={institutionProfileStyles.heading}
                fontSize={['25px', '20px', '40px', '40px']}
              >
                {institution?.name}
              </Text>
            </Box>

            <Box width={['100%']}>
              <Text
                sx={institutionProfileStyles.subHeading}
                fontSize={['25px', '20px', '40px', '40px']}
              >
                {`Nivel ${institution?.Level.description} - Modalidad ${institution?.InstitutionType?.description}`}
              </Text>
            </Box>
            <Box width={['100%']}>
              <Text sx={institutionProfileStyles.subHeadingMini} textTransform='capitalize'>
                {`${institution?.Address?.Province?.description.toLowerCase()} -
                 ${institution?.Address?.Department?.description.toLowerCase()} -
                 ${institution?.Address?.Locality?.description.toLowerCase()}`}
              </Text>
            </Box>
          </Box>

          {/* BODY CONTAINER */}
          <Box p={['20px', '40px 68px', '40px 50px']}>
            <Card
              boxShadow='10px 10px 40px 0.5px rgb(0 0 0 / 10%)'
              borderRadius='12px'
              p={['20px', '40px 68px', '40px 50px']}
            >
              {institution.description !== null && (
                <CardHeader p='0'>
                  <Heading sx={institutionProfileStyles.bodyTitle}>Descripción</Heading>
                  <Box mt='24px'>
                    <Text>{institution.description}</Text>
                  </Box>
                </CardHeader>
              )}

              <CardBody p='0' mt='40px'>
                {/* BODY TITLE */}
                <Box>
                  <Heading sx={institutionProfileStyles.bodyTitle}>Datos de la institución</Heading>
                </Box>
                {/* FILA 1 */}
                <Box
                  display='flex'
                  flexDirection={['column', 'row']}
                  flexWrap='wrap'
                  p='0'
                  mt='24px'
                >
                  <Box
                    w={['100%', '33%', '50%', '33%', '33%']}
                    p='0'
                    display='flex'
                    // mt={['15px', '30px']}
                  >
                    {/* Número provincial */}
                    <Text sx={institutionProfileStyles.adDataItemReferenceText}>
                      Número provincial:&nbsp;
                    </Text>
                    {institution.provincialNumber !== null ? institution.provincialNumber : '-'}
                  </Box>

                  {/* Región */}
                  <Box
                    w={['100%', '33%', '50%', '33%', '33%']}
                    p='0'
                    display='flex'
                    mt={['15px', '30px', '24px', '0']}
                  >
                    <Box
                      w={['100%', '100%', '100%', '100%', '75%', '75%']}
                      display='flex'
                      ml={['0', '0', '0', '10%', '15%', '30%']}
                    >
                      <Text sx={institutionProfileStyles.adDataItemReferenceText}>
                        Región:&nbsp;
                      </Text>
                      {institution.Region ? institution.Region.description ?? '-' : '-'}
                    </Box>
                  </Box>

                  {/* Calle */}
                  <Box
                    w={['100%', '33%', '50%', '33%', '33%']}
                    p='0'
                    display='flex'
                    mt={['15px', '30px', '24px', '0']}
                  >
                    <Box
                      w={['100%', '100%', '100%', '100%', '75%', '85%']}
                      display='flex'
                      ml={['0', '0', '0', '10%', '25%', '65%']}
                    >
                      <Text sx={institutionProfileStyles.adDataItemReferenceText}>
                        Calle:&nbsp;
                      </Text>
                      {institution.Address?.street !== null && institution.Address?.street
                        ? institution.Address?.street?.toLowerCase()
                        : '-'}
                    </Box>
                  </Box>
                  <Box
                    w={['100%', '33%', '50%', '33%', '33%']}
                    p='0'
                    display='flex'
                    mt={['15px', '30px', '24px']}
                  >
                    {/* Número */}
                    <Text sx={institutionProfileStyles.adDataItemReferenceText}>
                      Número:&nbsp;{' '}
                    </Text>
                    {institution.Address?.number ?? '-'}
                  </Box>

                  <Box
                    w={['100%', '33%', '50%', '33%', '33%']}
                    p='0'
                    display='flex'
                    mt={['15px', '30px', '24px']}
                  >
                    <Box
                      w={['100%', '100%', '100%', '100%', '75%', '75%']}
                      display='flex'
                      ml={['0', '0', '0', '10%', '15%', '30%']}
                    >
                      {/* Código postal */}
                      <Text sx={institutionProfileStyles.adDataItemReferenceText}>
                        Código postal:&nbsp;{' '}
                      </Text>
                      {institution.Address?.postalCode !== null && institution.Address?.postalCode
                        ? institution.Address?.postalCode
                        : '-'}
                    </Box>
                  </Box>

                  {/* Característica */}
                  <Box
                    w={['100%', '33%', '50%', '33%', '33%']}
                    p='0'
                    display='flex'
                    mt={['15px', '30px', '24px']}
                  >
                    <Box
                      w={['100%', '100%', '100%', '100%', '75%', '85%']}
                      display='flex'
                      ml={['0', '0', '0', '10%', '25%', '65%']}
                    >
                      <Text sx={institutionProfileStyles.adDataItemReferenceText}>
                        Característica:&nbsp;
                      </Text>
                      <Text
                        sx={{
                          textTransform: 'capitalize',
                          ...institutionProfileStyles.adDataItemText,
                        }}
                      >
                        {institution?.LevelModalities?.length > 0
                          ? institution?.LevelModalities?.map((level: any) => (
                              <Text
                                key={level.Modality.id}
                                sx={{
                                  textTransform: 'capitalize',
                                  ...institutionProfileStyles.adDataItemText,
                                }}
                              >
                                {level.Modality.description}
                              </Text>
                            ))
                          : '-'}
                      </Text>
                    </Box>
                  </Box>
                  <Box
                    w={['100%', '33%', '50%', '33%', '33%']}
                    p='0'
                    display='flex'
                    mt={['15px', '30px', '24px']}
                  >
                    {/* CANTIDAD DE ALUMNOS */}
                    <Text sx={institutionProfileStyles.adDataItemReferenceText}>
                      Cantidad de alumnos:&nbsp;{' '}
                    </Text>
                    <Text>{institution?.StudentsQuantity.description ?? ''}</Text>
                  </Box>

                  <Box
                    w={['100%', '33%', '50%', '33%', '33%']}
                    p='0'
                    display='flex'
                    mt={['15px', '30px', '24px']}
                  >
                    <Box
                      w={['100%', '100%', '100%', '100%', '75%', '75%']}
                      display='flex'
                      ml={['0', '0', '0', '10%', '15%', '30%']}
                    >
                      {/* NIVEL */}
                      <Text sx={institutionProfileStyles.adDataItemReferenceText}>
                        Cantidad de empleados:&nbsp;
                      </Text>
                      {institution.EmployeesQuantity.description ?? '-'}
                    </Box>
                  </Box>
                </Box>
                <HStack my={['25px', '40px']}>
                  <Divider />
                </HStack>

                {/* CONTACTO */}
                <Box display='flex' flexDirection={['column', 'column']} p='0' mt={['0px', '24px']}>
                  <Box>
                    <Heading sx={institutionProfileStyles.bodyTitle}>Contacto</Heading>
                  </Box>

                  <Box display='flex' flexDirection={['column', 'row']} p='0' mt={['0', '24px']}>
                    {/* 1 - Instagram */}
                    <Box w={['100%', '33%', '33%']} p='0' display='flex' mt={['15px', '0']}>
                      <Text sx={institutionProfileStyles.dataReferenceText}>
                        <Image
                          src={IconInstagram}
                          alt={'Instagram'}
                          width={25}
                          height={25}
                          mr={3}
                          color='#56769A'
                        />
                      </Text>

                      {institution.instagramURL ? (
                        <RouterLink
                          target='_blank'
                          style={{ color: colors.institutionalRed }}
                          to={`https://www.instagram.com/${institution.instagramURL}`}
                        >
                          @{institution.instagramURL}
                        </RouterLink>
                      ) : (
                        '-'
                      )}
                    </Box>

                    {/* 2 - Facebook */}
                    <Box w={['100%', '33%', '33%']} p='0' display='flex' mt={['15px', '0']}>
                      <Text sx={institutionProfileStyles.dataReferenceText}>
                        <Image src={IconFacebook} alt={'Facebook'} width={25} height={25} mr={3} />
                      </Text>
                      {institution.facebookURL ? (
                        <RouterLink
                          target='_blank'
                          style={{ color: colors.institutionalRed }}
                          to={`https://www.facebook.com/${institution.facebookURL}`}
                        >
                          @{institution.facebookURL}
                        </RouterLink>
                      ) : (
                        '-'
                      )}
                    </Box>

                    {/* 3 - Telegram */}
                    <Box w={['100%', '33%', '33%']} p='0' display='flex' mt={['15px', '0']}>
                      <Text sx={institutionProfileStyles.dataReferenceText}>
                        <Image src={IconTelegram} alt={'Telegram'} width={25} height={25} mr={3} />
                      </Text>
                      {institution.twitterURL ? (
                        <RouterLink
                          target='_blank'
                          style={{ color: colors.institutionalRed }}
                          to={`https://www.twitter.com/${institution.twitterURL}`}
                        >
                          @{institution.twitterURL}
                        </RouterLink>
                      ) : (
                        '-'
                      )}
                    </Box>
                  </Box>

                  <Box display='flex' flexDirection={['column', 'row']} p='0' mt={['0px', '24px']}>
                    {/* 4 - Linkedin */}
                    <Box w={['100%', '33%', '33%']} p='0' display='flex' mt={['15px', '10px']}>
                      <Text sx={institutionProfileStyles.dataReferenceText}>
                        <Image src={IconLinkedin} alt={'Linkedin'} width={25} height={25} mr={3} />
                      </Text>

                      {institution.linkedinURL ? (
                        <RouterLink
                          target='_blank'
                          style={{ color: colors.institutionalRed }}
                          to={`https://www.linkedin.com/${institution.linkedinURL}`}
                        >
                          @{institution.linkedinURL}
                        </RouterLink>
                      ) : (
                        '-'
                      )}
                    </Box>

                    {/* 5 - Página oficial */}
                    <Box w={['100%', '33%', '33%']} p='0' display='flex' mt={['15px', '10px']}>
                      <Text sx={institutionProfileStyles.dataReferenceText}>
                        <Image
                          src={IconOfficialWeb}
                          alt={'Sitio web oficial'}
                          width={25}
                          height={25}
                          mr={3}
                        />
                      </Text>
                      {institution.webURL ? (
                        <RouterLink
                          target='_blank'
                          style={{ color: colors.institutionalRed }}
                          to={`https://${institution.webURL}`}
                        >
                          {`https://${institution.webURL}`}
                        </RouterLink>
                      ) : (
                        '-'
                      )}
                    </Box>
                  </Box>
                </Box>
              </CardBody>
            </Card>
          </Box>
        </Box>
      ) : (
        <Loader props={institutionProfileStyles.loaderStyles} />
      )}
    </>
  )
}

export default InstitutionProfile
