import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormLabel,
  Heading,
  Icon,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  UseToastOptions,
  useMediaQuery,
  useToast,
} from '@chakra-ui/react'
import { CheckIcon, CloseIcon, DeleteIcon, EditIcon } from '@chakra-ui/icons'
import {
  JobsExperienceRules,
  JobsExperienceRulesActions,
  SocialSkillsRules,
  SocialSkillsRulesAction,
} from './_types'
import {
  jobsExperienceDeleteClean,
  jobsExperienceDeleteRequest,
  jobsExperienceEditClean,
  jobsExperienceEditRequest,
  socialSkillDeleteClean,
  socialSkillDeleteRequest,
  userInformationRequest,
} from '../../../../state/modules/user/actions'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useRef, useState } from 'react'

import Loader from '../../../loader'
import { colors } from '../../../../utils/colors'
import { experienceTableStyles } from './styles'
import { log } from 'console'

const toastMessages: UseToastOptions[] = [
  {
    description: 'Datos eliminados correctamente',
    status: 'success',
    duration: 2000,
  },
  {
    description: 'Ocurrio un error',
    status: 'error',
    duration: 2000,
  },
]

const toastMessagesUpdate: UseToastOptions[] = [
  {
    description: 'Datos actulizados correctamente',
    status: 'success',
    duration: 2000,
  },
  {
    description: 'Ocurrio un error',
    status: 'error',
    duration: 2000,
  },
]

const JobsExperienceEdit = () => {
  const componentRef = useRef<HTMLDivElement>(null)
  const [anchoMaximo, setAnchoMaximo] = useState(0)
  const dispatch = useDispatch()
  const userInformation = useSelector((state: any) => state?.user?.userInformation ?? '')

  const [isMobile] = useMediaQuery('(max-width: 600px)')
  const state = useSelector((state: any) => state)

  const [jobs, setJobs] = useState<JobsExperienceRules[]>([])
  const [socialSkills, setSocialSkills] = useState<SocialSkillsRules[]>([])
  const [editedValues, setEditedValues] = useState<JobsExperienceRulesActions>({
    token: '',
    id: '',
    jobPosition: '',
    dateFrom: '',
    institution: '',
    jobDescription: '',
    referenceName: '',
    referenceEmail: '',
    referencePhoneNumber: '',
    dateTo: '',
    currently: false,
  })
  const [isEditing, setIsEditing] = useState(false)
  const [editingItem, setEditingItem] = useState<JobsExperienceRulesActions | null>(null)
  const [isCurrentEdited, setIsCurrentEdited] = useState<any>(null)
  const [dateError, setDateError] = useState<any>({ dateFrom: false, dateTo: false })
  const [dateErrorMsg, setdateErrorMsg] = useState<any>(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const {
    jobsExperienceSuccess,
    deleteJobsExperienceSuccess,
    editJobsExperienceSuccess,
    deleteSocialSkillsSuccess,
  } = useSelector((state: any) => state?.user ?? '')
  const toast = useToast()

  useEffect(() => {
    if (deleteJobsExperienceSuccess !== null && deleteJobsExperienceSuccess !== undefined) {
      toast(toastMessages[deleteJobsExperienceSuccess ? 0 : 1])
      dispatch(jobsExperienceDeleteClean())
    }
  }, [deleteJobsExperienceSuccess])

  useEffect(() => {
    if (deleteSocialSkillsSuccess !== null && deleteSocialSkillsSuccess !== undefined) {
      toast(toastMessages[deleteSocialSkillsSuccess ? 0 : 1])
      dispatch(socialSkillDeleteClean())
    }
  }, [deleteSocialSkillsSuccess])

  useEffect(() => {
    if (editJobsExperienceSuccess !== null && editJobsExperienceSuccess !== undefined) {
      toast(toastMessagesUpdate[editJobsExperienceSuccess ? 0 : 1])
      dispatch(jobsExperienceEditClean())
    }
  }, [editJobsExperienceSuccess])

  useEffect(() => {
    if (componentRef.current) {
      const width = componentRef.current.offsetWidth
      setAnchoMaximo(width)
    }
  }, [])

  useEffect(() => {
    if (
      deleteJobsExperienceSuccess ||
      deleteSocialSkillsSuccess ||
      jobsExperienceSuccess ||
      editJobsExperienceSuccess
    ) {
      dispatch(userInformationRequest({ userId: userInformation.id }))
      editJobsExperienceSuccess && setTimeout(() => setIsDeleting(false), 1000)
    }
  }, [
    deleteJobsExperienceSuccess,
    deleteSocialSkillsSuccess,
    jobsExperienceSuccess,
    editJobsExperienceSuccess,
  ])

  useEffect(() => {
    userInformation === undefined || userInformation === null || userInformation === ''
      ? null
      : setearDatos()
  }, [userInformation])

  useEffect(() => {
    if (dateErrorMsg?.dateFrom) {
      toast({ description: dateErrorMsg?.dateFrom, status: 'error', duration: 2000 })
      setdateErrorMsg((current: any) => {
        return {
          ...current,
          dateFrom: false,
        }
      })
    }

    if (dateErrorMsg?.dateTo) {
      toast({ description: dateErrorMsg?.dateTo, status: 'error', duration: 2000 })
      setdateErrorMsg((current: any) => {
        return {
          ...current,
          dateTo: false,
        }
      })
    }
  }, [dateErrorMsg])

  const setearDatos = () => {
    const jobsarray = userInformation.JobExperiences.filter((item: JobsExperienceRules) => item)
    setJobs(jobsarray)
    const socialskillarray = userInformation.SocialSkills.filter((item: any) => item)
    setSocialSkills(socialskillarray)
  }

  const fechaFromater = (fechasinformato: any) => {
    const fecha = new Date(fechasinformato)

    const dia = fecha.getDate()
    const mes = fecha.getMonth() + 1
    const anio = fecha.getFullYear()

    const diaFormateado = ('0' + dia).slice(-2)
    const mesFormateado = ('0' + mes).slice(-2)

    const fechaFormateada = `${mesFormateado}/${diaFormateado}/${anio}`

    return fechaFormateada
  }

  const editJobsExperience = (jobs: JobsExperienceRules) => {
    setIsEditing(true)
    const token = localStorage.getItem('access_token') ?? ''
    const updatedInitialValuesForm: JobsExperienceRulesActions = {
      token: token,
      id: jobs.id,
      jobPosition: jobs.jobPosition,
      dateFrom: new Date(jobs.dateFrom).toISOString().split('T')[0],
      institution: jobs.institution,
      jobDescription: jobs.jobDescription,
      referenceName: jobs.referenceName,
      referenceEmail: jobs.referenceEmail,
      referencePhoneNumber: jobs.referencePhoneNumber,
      dateTo:
        jobs.dateTo === null
          ? new Date().toISOString().split('T')[0]
          : new Date(jobs.dateTo).toISOString().split('T')[0],
      currently: jobs.currently,
    }
    setEditedValues(updatedInitialValuesForm)
    setEditingItem(updatedInitialValuesForm)
  }

  const saveJobsExperience = () => {
    setIsEditing(false)
    const { token, id, ...rest } = editedValues
    const updatedValues = {
      id,
      token,
      jobsExperiences: rest,
      socialSkills: null,
    }

    dispatch(jobsExperienceEditRequest(updatedValues))
    setEditingItem(null)
  }
  const deleteJobsExperience = async (jobs: JobsExperienceRules) => {
    setIsDeleting(true)

    const token = localStorage.getItem('access_token') ?? ''

    const updatedInitialValuesForm: JobsExperienceRulesActions = {
      token: token,
      id: jobs.id,
      jobPosition: jobs.jobPosition,
      dateFrom: fechaFromater(jobs.dateFrom),
      institution: jobs.institution,
      jobDescription: jobs.jobDescription,
      referenceName: jobs.referenceName,
      referenceEmail: jobs.referenceEmail,
      referencePhoneNumber: jobs.referencePhoneNumber,
      dateTo: fechaFromater(jobs.dateTo),
      currently: jobs.currently,
    }

    await dispatch(jobsExperienceDeleteRequest(updatedInitialValuesForm))

    setIsDeleting(false)
  }

  const deleteJobsExperienceSkills = (jobs: SocialSkillsRules) => {
    const token = localStorage.getItem('access_token') ?? ''
    const updatedInitialValuesForm: SocialSkillsRulesAction = {
      token: token,
      id: jobs.id,
      userId: state.user.userInformation.id,
    }
    dispatch(socialSkillDeleteRequest(updatedInitialValuesForm))
  }

  const handleDateToChange = (e: any, jobs: any) => {
    const dateTo = e.target.value.split('T')[0]

    dateTo < editedValues.dateFrom
      ? setDateError((current: any) => {
          return {
            ...current,
            dateTo: true,
          }
        })
      : setDateError((current: any) => {
          return {
            ...current,
            dateTo: false,
          }
        })

    setEditedValues((prevValues) => ({
      ...prevValues,
      dateTo: e.target.value,
      id: jobs.id,
      type: jobs.type,
      userId: jobs.userId,
    }))
  }

  const handleDateToBlur = (e: any, editedValues: any) => {
    const dateTo = e.target.value.split('T')[0]
    dateTo < editedValues.dateFrom &&
      setdateErrorMsg((current: any) => {
        return {
          ...current,
          dateTo: 'La fecha de finalización no puede ser menor a la fecha de inicio',
        }
      })
  }

  const handleDateFromChange = (e: any, jobs: any) => {
    const dateFrom = e.target.value.split('T')[0]
    const today = new Date().toISOString().split('T')[0]

    dateFrom > today
      ? setDateError((current: any) => {
          return {
            ...current,
            dateTo: true,
          }
        })
      : setDateError((current: any) => {
          return {
            ...current,
            dateTo: false,
          }
        })
    setEditedValues((prevValues) => ({
      ...prevValues,
      dateFrom: e.target.value,
      id: jobs.id,
      type: jobs.type,
      userId: jobs.userId,
    }))
  }

  const handleDateFromBlur = (e: any) => {
    const dateFrom = e.target.value.split('T')[0]
    const today = new Date().toISOString().split('T')[0]

    dateFrom > today &&
      setdateErrorMsg((current: any) => {
        return {
          ...current,
          dateFrom: ' La fecha de inicio no puede ser superior al día de hoy',
        }
      })
  }

  return (
    <>
      {!userInformation ? (
        <Loader props={experienceTableStyles.loader} />
      ) : socialSkills.length !== 0 || jobs.length !== 0 ? (
        <Box sx={experienceTableStyles.tablesContainer}>
          {jobs.length !== 0 && (
            <Box
              display={isMobile ? 'block' : 'block'}
              justifyContent={'flex-start'}
              alignItems={'center'}
            >
              <Box>
                <Heading sx={experienceTableStyles.tableHeading}>Experiencias Laborales</Heading>

                <TableContainer>
                  <Table colorScheme='facebook'>
                    <Thead>
                      <Tr>
                        <Th
                          textTransform={'initial'}
                          sx={{ ...experienceTableStyles.tableHead, width: '10%' }}
                        >
                          Fecha desde
                        </Th>
                        <Th
                          textTransform={'initial'}
                          sx={{ ...experienceTableStyles.tableHead, width: '10%' }}
                        >
                          Fecha de Finalización
                        </Th>
                        <Th
                          textTransform={'initial'}
                          sx={{ ...experienceTableStyles.tableHead, width: '20%' }}
                        >
                          Nombre del puesto
                        </Th>
                        <Th
                          textTransform={'initial'}
                          sx={{ ...experienceTableStyles.tableHead, width: '20%' }}
                        >
                          Tareas realizadas
                        </Th>
                        <Th
                          textTransform={'initial'}
                          sx={{ ...experienceTableStyles.tableHead, width: '10%' }}
                        >
                          Institución / Empresa
                        </Th>
                        <Th
                          textTransform={'initial'}
                          sx={{ ...experienceTableStyles.tableHead, width: '10%' }}
                        >
                          Nombre de referencia
                        </Th>
                        <Th
                          textTransform={'initial'}
                          sx={{ ...experienceTableStyles.tableHead, width: '10%' }}
                        >
                          Email de referencia
                        </Th>
                        <Th
                          textTransform={'initial'}
                          sx={{ ...experienceTableStyles.tableHead, width: '10%' }}
                        >
                          Teléfono de referencia
                        </Th>
                        <Th
                          textTransform={'initial'}
                          sx={{ ...experienceTableStyles.tableHead, width: '10%' }}
                        >
                          Acción
                        </Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {jobs.map((jobs, index) => {
                        const today = new Date().toISOString().split('T')[0]

                        return (
                          <Tr key={index}>
                            <Td sx={experienceTableStyles.tableData}>
                              {editingItem && editingItem.id === jobs.id ? (
                                <input
                                  type='date'
                                  max={today}
                                  className={isEditing ? 'editing-input' : ''}
                                  value={editedValues.dateFrom}
                                  onChange={(e) => handleDateFromChange(e, jobs)}
                                  onBlur={(e) => handleDateFromBlur(e)}
                                />
                              ) : (
                                new Date(jobs.dateFrom)
                                  .toISOString()
                                  .split('T')[0]
                                  .split('-')
                                  .reverse()
                                  .join('/')
                              )}
                            </Td>
                            <Td sx={experienceTableStyles.tableData}>
                              {editingItem && editingItem.id === jobs.id && jobs.dateTo != null ? (
                                <input
                                  type='date'
                                  max={'2050-01-01'}
                                  min={editedValues.dateFrom}
                                  className={isEditing ? 'editing-input' : ''}
                                  value={editedValues.dateTo}
                                  onChange={(e) => handleDateToChange(e, jobs)}
                                  onBlur={(e) => handleDateToBlur(e, editedValues)}
                                />
                              ) : editingItem &&
                                editingItem.id === jobs.id &&
                                jobs.dateTo == null ? (
                                <Box style={{ display: 'flex' }}>
                                  {isCurrentEdited?.id === jobs.id ? (
                                    <input
                                      type='date'
                                      max={'2050-01-01'}
                                      min={editedValues.dateFrom}
                                      className={isEditing ? 'editing-input' : ''}
                                      defaultValue={today}
                                      onChange={(e) => handleDateToChange(e, jobs)}
                                      onBlur={(e) => handleDateToBlur(e, editedValues)}
                                    />
                                  ) : (
                                    <FormLabel sx={experienceTableStyles.checkLabel}>
                                      Actualmente
                                      <Checkbox
                                        defaultChecked
                                        onChange={() =>
                                          setIsCurrentEdited({
                                            id: jobs?.id,
                                          })
                                        }
                                      />
                                    </FormLabel>
                                  )}
                                </Box>
                              ) : jobs.dateTo != null ? (
                                new Date(jobs.dateTo)
                                  .toISOString()
                                  .split('T')[0]
                                  .split('-')
                                  .reverse()
                                  .join('/')
                              ) : (
                                'Actualmente'
                              )}
                            </Td>
                            <Td sx={experienceTableStyles.tableData}>
                              {editingItem && editingItem.id === jobs.id ? (
                                <input
                                  type='text'
                                  placeholder='nombre del puesto'
                                  className={isEditing ? 'editing-input' : ''}
                                  value={editedValues.jobPosition}
                                  onChange={(e) =>
                                    setEditedValues((prevValues) => ({
                                      ...prevValues,
                                      jobPosition: e.target.value,
                                      id: jobs.id,
                                      type: jobs.type,
                                      userId: jobs.userId,
                                    }))
                                  }
                                />
                              ) : (
                                jobs.jobPosition
                              )}
                            </Td>
                            <Td sx={experienceTableStyles.tableData}>
                              {editingItem && editingItem.id === jobs.id ? (
                                <input
                                  type='text'
                                  placeholder='tareas realizadas'
                                  className={isEditing ? 'editing-input' : ''}
                                  value={editedValues.jobDescription}
                                  onChange={(e) =>
                                    setEditedValues((prevValues) => ({
                                      ...prevValues,
                                      jobDescription: e.target.value,
                                      id: jobs.id,
                                      type: jobs.type,
                                      userId: jobs.userId,
                                    }))
                                  }
                                />
                              ) : (
                                jobs.jobDescription
                              )}
                            </Td>
                            <Td sx={experienceTableStyles.tableData}>
                              {editingItem && editingItem.id === jobs.id ? (
                                <input
                                  type='text'
                                  placeholder='nombre institución'
                                  className={isEditing ? 'editing-input' : ''}
                                  value={editedValues.institution}
                                  onChange={(e) =>
                                    setEditedValues((prevValues) => ({
                                      ...prevValues,
                                      institution: e.target.value,
                                      id: jobs.id,
                                      type: jobs.type,
                                      userId: jobs.userId,
                                    }))
                                  }
                                />
                              ) : (
                                jobs.institution
                              )}
                            </Td>
                            <Td sx={experienceTableStyles.tableData}>
                              {editingItem && editingItem.id === jobs.id ? (
                                <input
                                  type='text'
                                  placeholder='nombre de referencia'
                                  className={isEditing ? 'editing-input' : ''}
                                  value={editedValues.referenceName}
                                  onChange={(e) =>
                                    setEditedValues((prevValues) => ({
                                      ...prevValues,
                                      referenceName: e.target.value,
                                      id: jobs.id,
                                      type: jobs.type,
                                      userId: jobs.userId,
                                    }))
                                  }
                                />
                              ) : jobs?.referenceName !== '' ? (
                                jobs?.referenceName
                              ) : (
                                '-'
                              )}
                            </Td>
                            <Td sx={experienceTableStyles.tableData}>
                              {editingItem && editingItem.id === jobs.id ? (
                                <input
                                  type='text'
                                  placeholder='email de referencia'
                                  className={isEditing ? 'editing-input' : ''}
                                  value={editedValues.referenceEmail}
                                  onChange={(e) =>
                                    setEditedValues((prevValues) => ({
                                      ...prevValues,
                                      referenceEmail: e.target.value,
                                      id: jobs.id,
                                      type: jobs.type,
                                      userId: jobs.userId,
                                    }))
                                  }
                                />
                              ) : jobs?.referenceEmail !== '' ? (
                                jobs?.referenceEmail
                              ) : (
                                '-'
                              )}
                            </Td>
                            <Td sx={experienceTableStyles.tableData}>
                              {editingItem && editingItem.id === jobs.id ? (
                                <input
                                  type='text'
                                  placeholder='telefono de referencia'
                                  className={isEditing ? 'editing-input' : ''}
                                  value={editedValues.referencePhoneNumber}
                                  onChange={(e) =>
                                    setEditedValues((prevValues) => ({
                                      ...prevValues,
                                      referencePhoneNumber: e.target.value,
                                      id: jobs.id,
                                      type: jobs.type,
                                      userId: jobs.userId,
                                    }))
                                  }
                                />
                              ) : jobs?.referencePhoneNumber !== '' ? (
                                jobs.referencePhoneNumber
                              ) : (
                                '-'
                              )}
                            </Td>
                            <Td sx={experienceTableStyles.tableData}>
                              <Flex alignItems='center' justifyContent='center' columnGap={1}>
                                {editingItem && editingItem.id === jobs.id ? (
                                  <Flex
                                    alignItems='center'
                                    justifyContent='space-between'
                                    columnGap={1}
                                  >
                                    <Tooltip label='Aceptar' background={colors.principal}>
                                      <Icon
                                        as={CheckIcon as React.ElementType}
                                        boxSize={3}
                                        cursor={
                                          dateError?.dateFrom || dateError?.dateTo
                                            ? 'initial'
                                            : 'pointer'
                                        }
                                        color={
                                          dateError?.dateFrom || dateError?.dateTo
                                            ? 'grey'
                                            : '#1D8348'
                                        }
                                        onClick={() => {
                                          !dateError?.dateFrom &&
                                            !dateError?.dateTo &&
                                            saveJobsExperience()
                                        }}
                                      />
                                    </Tooltip>
                                    <Tooltip label='Cancelar' background={colors.principal}>
                                      <Icon
                                        as={CloseIcon}
                                        boxSize={2}
                                        color={'#283747'}
                                        onClick={() => {
                                          setIsEditing(false)
                                          setIsCurrentEdited(null)
                                          setEditingItem(null)
                                        }}
                                      />
                                    </Tooltip>
                                  </Flex>
                                ) : (
                                  <>
                                    <Tooltip label='Editar' background={colors.principal}>
                                      <Button
                                        sx={{
                                          ...experienceTableStyles.actionButton,
                                          minWidth: 'unset',
                                        }}
                                        onClick={() => editJobsExperience(jobs)}
                                        width={5}
                                        height={5}
                                        _hover={{ bg: 'none' }}
                                      >
                                        <Icon
                                          _hover={{ bg: 'none' }}
                                          as={EditIcon as React.ElementType}
                                          boxSize={3}
                                          color={'#2980B9'}
                                        />
                                      </Button>
                                    </Tooltip>
                                    <Tooltip label='Eliminar' background={colors.principal}>
                                      <Button
                                        size={'xs'}
                                        sx={{
                                          ...experienceTableStyles.actionButton,
                                          minWidth: 'unset',
                                        }}
                                        _hover={{ bg: 'none' }}
                                        isDisabled={isDeleting}
                                        width={5}
                                        height={5}
                                        onClick={() => deleteJobsExperience(jobs)}
                                      >
                                        <Icon
                                          as={DeleteIcon as React.ElementType}
                                          boxSize={3}
                                          color={'#CB4335'}
                                        />
                                      </Button>
                                    </Tooltip>
                                  </>
                                )}
                              </Flex>
                            </Td>
                          </Tr>
                        )
                      })}
                    </Tbody>
                  </Table>
                </TableContainer>
              </Box>
            </Box>
          )}
          {socialSkills.length !== 0 && (
            <Box display={'block'}>
              <Box>
                <Heading sx={experienceTableStyles.tableHeading}>Habilidades sociales</Heading>
                <TableContainer>
                  <Table colorScheme='facebook'>
                    <Thead>
                      <Tr>
                        <Th
                          textTransform={'initial'}
                          sx={{ ...experienceTableStyles.tableHead, width: '92%' }}
                        >
                          Habilidades
                        </Th>
                        <Th
                          textTransform={'initial'}
                          sx={{ ...experienceTableStyles.tableHead, width: '8%' }}
                        >
                          Acción
                        </Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {socialSkills.map((item, index) => (
                        <Tr key={index}>
                          <Td sx={experienceTableStyles.tableData}>{item.description}</Td>
                          <Td sx={{ ...experienceTableStyles.tableData, textAlign: 'center' }}>
                            <Tooltip label='Eliminar' background={colors.principal}>
                              <Icon
                                as={DeleteIcon as React.ElementType}
                                boxSize={3}
                                color={'#CB4335'}
                                onClick={() => deleteJobsExperienceSkills(item)}
                              />
                            </Tooltip>
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </TableContainer>
                <br />
              </Box>
            </Box>
          )}
        </Box>
      ) : (
        <Box sx={experienceTableStyles.noResults}>
          <Text>*No existen registros asociados</Text>
        </Box>
      )}
    </>
  )
}

export default JobsExperienceEdit
