import {
  Box,
  Icon,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Thead,
  Tooltip,
  Tr,
} from '@chakra-ui/react'
import { DownloadIcon, ViewIcon } from '@chakra-ui/icons'
import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ApplicationsDataField } from '../ApplicationsHandlerFilter/_types'
import { ApplicationsHandlerTableProps } from './_types'
import { Link } from 'react-router-dom'
import Loader from '../../loader'
import Paginator from '../../Paginator'
import { TbEyeCheck } from 'react-icons/tb'
import { applicationsHandlerFields } from '../../../utils/jobApplications'
import { applicationsHandlerTableStyles } from './styles'
import { colors } from '../../../utils/colors'
import { resumeDownloadRequest } from '../../../state/modules/user/actions'

const ApplicationsHandlerTable: FC<ApplicationsHandlerTableProps> = ({
  currentPage,
  setCurrentPage,
  filters,
  setResumeName,
}) => {
  const handleApplicationList = useSelector(
    (state: any) => state?.jobApplications?.handleApplicationsList,
  )
  const dispatch = useDispatch()

  const handleDownloadProfile = (userId: string, profile: any, institutionId: string) => {
    dispatch(resumeDownloadRequest({ userId, photo: 'true', institutionId }))
    const name = profile?.name + '_' + profile.lastName
    setResumeName(name)
  }

  return (
    <Box sx={applicationsHandlerTableStyles.container}>
      {filters === null ? (
        <Box sx={applicationsHandlerTableStyles.noResultsContainer}>
          <Text sx={applicationsHandlerTableStyles.noResults}>
            *No se han realizado búsquedas aún
          </Text>
        </Box>
      ) : handleApplicationList?.rows ? (
        handleApplicationList?.rows?.length === 0 ? (
          <Box sx={applicationsHandlerTableStyles.noResultsContainer}>
            <Text sx={applicationsHandlerTableStyles.noResults}>
              *No existen individuos que coincidan con la búsqueda
            </Text>
          </Box>
        ) : (
          <>
            <TableContainer>
              <Table colorScheme='facebook'>
                <Thead>
                  <Tr>
                    {applicationsHandlerFields
                      .filter((e) => e.isShown)
                      .map((field: ApplicationsDataField) => {
                        const label =
                          field.id === 'positionType'
                            ? 'Registrado como'
                            : field.id === 'teacherGraduated'
                            ? 'Título'
                            : field.name

                        return (
                          <Td
                            sx={{
                              width: `${field.width}%`,
                              minWidth: `${field.width}%`,
                              ...applicationsHandlerTableStyles.tableHead,
                            }}
                            key={field.order}
                          >
                            {label}
                          </Td>
                        )
                      })}
                    <Td
                      sx={{
                        width: '5%',
                        minWidth: '5%',
                        ...applicationsHandlerTableStyles.tableHead,
                      }}
                    >
                      Acción
                    </Td>
                  </Tr>
                </Thead>
                <Tbody>
                  {handleApplicationList?.rows?.map((e: any) => {
                    return (
                      <Tr
                        key={e?.id}
                        background={
                          e.User.ProfileVisualizations?.length > 0 ? 'white' : colors.unchecked
                        }
                      >
                        <Td sx={applicationsHandlerTableStyles.tableData}>
                          {e?.Ad?.Institutions?.length > 0
                            ? e?.Ad?.Institutions?.map((i: any) => <Text key={i.id}>*{i.cue}</Text>)
                            : '-'}
                        </Td>
                        <Td sx={applicationsHandlerTableStyles.tableData}>
                          {e?.referenceNumber && e?.referenceNumber != null
                            ? e?.referenceNumber
                            : '-'}
                        </Td>
                        <Td
                          sx={{
                            ...applicationsHandlerTableStyles.tableData,
                            textTransform: 'capitalize',
                          }}
                        >
                          {e?.Ad?.Position?.type ? e?.Ad?.Position?.type.toLowerCase() : '-'}
                        </Td>
                        <Td sx={applicationsHandlerTableStyles.tableData}>
                          {e?.Ad?.Position?.description ? e?.Ad?.Position?.description : '-'}
                        </Td>
                        <Td sx={applicationsHandlerTableStyles.tableData}>
                          {e?.Ad?.Levels?.length > 0
                            ? e?.Ad?.Levels?.map((i: any) => (
                                <Text key={i.id}>*{i.description}</Text>
                              ))
                            : '-'}
                        </Td>
                        <Td sx={applicationsHandlerTableStyles.tableData}>
                          {e?.Ad?.InstitutionTypes?.length > 0
                            ? e?.Ad?.InstitutionTypes?.map((i: any) => (
                                <Text key={i.id}>*{i.description}</Text>
                              ))
                            : '-'}
                        </Td>
                        <Td sx={applicationsHandlerTableStyles.tableData}>
                          {e?.User?.UserTitles?.length > 0
                            ? e?.User?.UserTitles?.map((i: any, index: number) => {
                                const title =
                                  i.type === 'curso' || i.type === 'nodocente'
                                    ? i.title
                                    : i.teacherTitle

                                return (
                                  <Text key={index}>
                                    *{i.type}: {title}
                                  </Text>
                                )
                              })
                            : '-'}
                        </Td>
                        <Td sx={applicationsHandlerTableStyles.tableData}>
                          {e?.Ad?.Status?.description}
                        </Td>
                        <Td sx={applicationsHandlerTableStyles.tableData}>
                          {e?.User?.Profile?.name && e?.User?.Profile?.name != null
                            ? e?.User?.Profile?.name
                            : '-'}
                        </Td>
                        <Td sx={applicationsHandlerTableStyles.tableData}>
                          {e?.User?.Profile?.lastName && e?.User?.Profile?.lastName != null
                            ? e?.User?.Profile?.lastName
                            : '-'}
                        </Td>
                        <Td
                          sx={{
                            ...applicationsHandlerTableStyles.tableData,
                            textTransform: 'capitalize',
                          }}
                        >
                          {e?.User?.Profile?.Address?.Department?.description
                            ? e?.User?.Profile?.Address?.Department?.description.toLowerCase()
                            : '-'}
                        </Td>

                        <Td sx={applicationsHandlerTableStyles.tableDataAction}>
                          <Link
                            to={`/candidates/profile/${e.userId}?institutionId=${filters.institutionId}`}
                            target='_blank'
                          >
                            <Tooltip label='Ver perfil' background={colors.principal}>
                              <span>
                                <Icon
                                  cursor='pointer'
                                  color={
                                    e.User.ProfileVisualizations?.length > 0
                                      ? colors.green
                                      : colors.black
                                  }
                                  boxSize={'12px'}
                                  as={ViewIcon as React.ElementType}
                                  margin={'0 3px'}
                                />
                              </span>
                            </Tooltip>
                          </Link>

                          <Tooltip label='Descargar perfil' background={colors.principal}>
                            <Icon
                              sx={applicationsHandlerTableStyles.tableDataIcon}
                              as={DownloadIcon as React.ElementType}
                              onClick={() =>
                                handleDownloadProfile(
                                  e.userId,
                                  e?.User?.Profile,
                                  filters.institutionId,
                                )
                              }
                            />
                          </Tooltip>
                        </Td>
                      </Tr>
                    )
                  })}
                </Tbody>
              </Table>
            </TableContainer>
            <Paginator
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
              elementsLength={handleApplicationList?.count}
              elementsPerpage={10}
              elements={handleApplicationList?.rows}
            />
          </>
        )
      ) : (
        <Loader props={applicationsHandlerTableStyles.loading} />
      )}
    </Box>
  )
}

export default ApplicationsHandlerTable
