import { YearsMonthsDays } from '../state/modules/user/services/types'

export function transformDateFromSQL(date: string) {
  const trimmed = date.slice(0, 10).split('-')
  const day = Number(trimmed[2])
  const month = trimmed[1]
  const year = trimmed[0]
  const day2 = Number(day)
  const dayMinusOne = day - 1
  const dayMinusOneIsOnlyDigit =
    dayMinusOne.toString().length === 1 && dayMinusOne !== 0 ? true : false

  const formatDate = `${year}-${month}-${
    dayMinusOneIsOnlyDigit ? `0${dayMinusOne}` : dayMinusOne !== 0 ? dayMinusOne : '01'
  }`
  const updateDate = new Date(`${year}-${month}-${day2 + 1}`)
  const updateAntiquityDate = new Date(`${year}-${month}-${day2 + 1}`)
  const updateAntiquitySelectorDate = new Date(`${year}-${month}-${day2 + 1}`)
  const profileDate = date === '' ? '' : `${day - 1}-${month}-${year}`
  const selectorDate = `${year}-${month}-${day}`
  const formatDateAntiquity = `${year}-${month}-${
    dayMinusOneIsOnlyDigit ? `0${dayMinusOne}` : dayMinusOne !== 0 ? dayMinusOne : '01'
  }`

  return {
    formatDate,
    updateDate,
    selectorDate,
    profileDate,
    updateAntiquitySelectorDate,
    formatDateAntiquity,
    updateAntiquityDate,
  }
}

export function formatProvinceId(id: string) {
  if (!id) {
    return ''
  } else if (id.length === 1) {
    return `0${id}`
  } else {
    return id
  }
}

export function formatLocalityId(id: string) {
  if (!id) {
    return ''
  } else if (id.length === 7) {
    return `0${id}`
  } else {
    return id
  }
}

export const getYears = () => {
  const years = []
  for (let index = 1960; index < 2061; index++) {
    years.push(index)
  }
  return years
}

export const month = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre',
]

export const preferenceFields = [
  {
    name: 'Postularse a',
    id: 'preferenceType',
    type: 'select',
    required: true,
    category: null,
    order: 1,
    length: null,
  },
  {
    name: 'Nivel',
    id: 'level',
    type: 'select',
    required: true,
    category: null,
    order: 2,
    length: null,
  },

  {
    name: 'Característica',
    id: 'levelModalities',
    type: 'select',
    required: false,
    category: null,
    order: 3,
    length: null,
  },
  {
    name: 'Modalidad',
    id: 'institutionTypes',
    type: 'select',
    required: true,
    category: null,
    order: 4,
    length: null,
  },
  {
    name: 'Puesto de Trabajo',
    id: 'position',
    type: 'multipleSelect',
    required: true,
    category: null,
    order: 5,
    length: null,
  },
  {
    name: 'Otro Puesto de Trabajo',
    id: 'otherPosition',
    type: 'text',
    required: true,
    category: null,
    order: 6,
    length: 30,
  },
  {
    name: 'Áreas Curriculares',
    id: 'educationAreas',
    type: 'multipleSelect',
    required: true,
    category: 'teacher',
    order: 7,
    length: null,
  },
  {
    name: 'Otra Área Curricular',
    id: 'otherEducationArea',
    type: 'text',
    required: true,
    category: 'teacher',
    order: 8,
    length: 30,
  },
  {
    name: 'Otras Áreas de Desarrollo Docente',
    id: 'extraEducationAreas',
    type: 'multipleSelect',
    required: true,
    category: 'teacher',
    order: 9,
    length: null,
  },
  {
    name: 'Otra Área de Desarrollo Docente',
    id: 'otherExtraEducationArea',
    type: 'text',
    required: true,
    category: 'teacher',
    order: 10,
    length: 30,
  },
  {
    name: 'Remuneración neta pretendida',
    id: 'intendedSalary',
    type: 'number',
    required: false,
    category: null,
    order: 11,
    length: 30,
  },
]

export const preferenceTypeOptions = [
  { value: 'docente', label: 'Docente' },
  { value: 'nodocente', label: 'No Docente' },
]

export const getRole = (institutions: any[], roleId: string) => {
  const isViewer = institutions?.length > 0 ? true : false
  const isAdmin =
    institutions?.length > 0 &&
    institutions?.find((institution: any) => institution?.Role?.id === '1')
      ? true
      : false
  const isAIEPBA = roleId === '3' ? true : false
  return { isAdmin, isAIEPBA, isViewer }
}

export const dateFormater = (date: any) => {
  const newDate = new Date(date)

  const day = newDate.getDate()
  const month = newDate.getMonth() + 1
  const year = newDate.getFullYear()

  const formattedDay = ('0' + day).slice(-2)
  const formattedMonth = ('0' + month).slice(-2)

  const fortmattedDate = `${formattedMonth}/${formattedDay}/${year}`

  return fortmattedDate
}

export const invitesHistoryFields = [
  {
    name: 'Nombre',
    id: 'inviteName',
    order: 1,
  },
  {
    name: 'Apellido',
    id: 'inviteLastname',
    order: 2,
  },
  {
    name: 'DNI',
    id: 'inviteDNI',
    order: 3,
  },
  {
    name: 'Email',
    id: 'inviteEmail',
    order: 4,
  },
  {
    name: 'Fecha',
    id: 'inviteDate',
    order: 5,
  },
  {
    name: 'Estado',
    id: 'inviteStatus',
    order: 6,
  },
  {
    name: 'Acción',
    id: 'action',
    order: 7,
  },
]

export const dateTimeUntilNow = (date: any, needToSlice = false): any => {
  let mappedDate

  if (needToSlice) {
    mappedDate = date.slice(0, 10)
  } else {
    mappedDate = date
  }

  const formattedDate = mappedDate.split('-')

  const today = new Date()
  const past = new Date(
    Number(formattedDate[0]),
    Number(formattedDate[1]) - 1,
    Number(formattedDate[2]),
  )

  let diff = Math.floor(today.getTime() - past.getTime())
  const lengthOfDayInSeconds = 1000 * 60 * 60 * 24,
    lengthOfMonthInSeconds = lengthOfDayInSeconds * 31,
    lengthOfYearInSeconds = lengthOfDayInSeconds * 365

  const yearsBetween = Math.floor(diff / lengthOfYearInSeconds)
  diff -= yearsBetween * lengthOfYearInSeconds

  const monthsBetween = Math.floor(diff / lengthOfMonthInSeconds)
  diff -= monthsBetween * lengthOfMonthInSeconds

  const daysBetween = Math.floor(diff / lengthOfDayInSeconds)

  const yearsMonthsDays: YearsMonthsDays = {
    years: yearsBetween,
    months: monthsBetween,
    days: daysBetween,
  }

  return yearsMonthsDays
}
