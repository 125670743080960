import { colors } from '../../utils/colors'

export const notificationsModalStyles = {
  title: { color: colors.principal },
  modalContainer: { padding: '1.5% 0' },
  handleNotificationContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    justifyContent: 'space-between',
  },
  modalBodyNotificationContainer: {
    gap: 30,
    display: 'flex',
    flexDirection: 'column',
  },
  handleNotificationTitle: { fontSize: '16px' },
  handleNotificationText: { fontSize: '12px' },
  switchContainer: { display: 'flex', alignItems: 'center', gap: '10px' },
  switchLabel: { fontSize: '12px' },
  switch: {},
  hover: {
    border: `1px solid ${colors.secundary}`,
    backgroundColor: colors.secundary,
    color: colors.white,
  },
  cancelButton: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: colors.white,
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '150%',
    borderRadius: '6px',
    padding: '0px 12px',
    color: colors.secundary,
  },
  cancelButtonMobile: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.white,
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '150%',
    borderRadius: '6px',
    padding: '8px 16px',
    color: colors.institutionalRed,
    border: `1px solid ${colors.institutionalRed}`,
  },
  saveButton: {
    backgroundColor: colors.principal,
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '150%',
    borderRadius: '6px',
    padding: '0px 12px',
    color: colors.white,
    border: `1px solid ${colors.principal}`,
  },
  saveButtonMobile: {
    backgroundColor: colors.principal,
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '150%',
    borderRadius: '6px',
    padding: '0px 16px',
    color: colors.white,
    border: `1px solid ${colors.principal}`,
  },

  buttonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    columnGap: '20px',
  },
}
