import {
  Box,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useMediaQuery,
  useToast,
} from '@chakra-ui/react'
import { FC, useEffect } from 'react'
import { viewRequestsModalStyles } from './styles'
import { CheckIcon, CloseIcon } from '@chakra-ui/icons'
import { useDispatch, useSelector } from 'react-redux'
import {
  handleViewRequisitionClean,
  handleViewRequisitionRequest,
  viewRequisitionsClean,
  viewRequisitionsRequest,
} from '../../state/modules/profiles/actions'
import Loader from '../loader'

export interface ViewRequestsModalProps {
  isOpen: boolean
  onClose: () => void
}

const ViewRequestsModal: FC<ViewRequestsModalProps> = ({ isOpen, onClose }) => {
  const [isMobile] = useMediaQuery('(max-width: 600px)')

  const profiles = useSelector((state: any) => state?.profiles)
  const viewRequests: any = useSelector((state: any) =>
    state?.profiles?.viewRequestsList?.filter((e: any) => !e.isPublic),
  )
  const dispatch = useDispatch()
  const toast = useToast()
  const toastDuration = 2000

  useEffect(() => {
    const token = localStorage.getItem('access_token') ?? ''
    if (isOpen) {
      dispatch(viewRequisitionsRequest(token))
    }
    return () => {
      dispatch(viewRequisitionsClean())
    }
  }, [isOpen])

  useEffect(() => {
    if (profiles?.handleRequestSuccess !== null && profiles?.handleRequestSuccess) {
      const token = localStorage.getItem('access_token') ?? ''

      toast({
        description: profiles?.handleRequestStatus?.message,
        status: 'success',
        duration: toastDuration,
      })
      dispatch(handleViewRequisitionClean())
      dispatch(viewRequisitionsRequest(token))
    }

    if (profiles?.handleRequestSuccess === false) {
      toast({
        description: profiles?.handleRequestStatus?.response?.data?.message,
        status: 'error',
        duration: toastDuration,
      })
      dispatch(handleViewRequisitionClean())
    }
  }, [profiles?.handleRequestSuccess])

  const handleReject = (requestId: string) => {
    const token = localStorage.getItem('access_token') ?? ''
    dispatch(handleViewRequisitionRequest({ token, approve: false, requestId }))
  }

  const handleApprove = (requestId: string) => {
    const token = localStorage.getItem('access_token') ?? ''
    dispatch(handleViewRequisitionRequest({ token, approve: true, requestId }))
  }
  return (
    <Modal scrollBehavior='inside' size={'2xl'} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent sx={viewRequestsModalStyles.modalContainer}>
        <ModalHeader sx={viewRequestsModalStyles.title}>Gestionar solicitudes</ModalHeader>
        <ModalCloseButton />
        <ModalBody padding={isMobile ? '4px 12px' : '8px 24px'}>
          {!viewRequests ? (
            <Loader props={viewRequestsModalStyles.loading} />
          ) : viewRequests.length === 0 ? (
            <Box sx={viewRequestsModalStyles.noResultsContainer}>
              <Text sx={viewRequestsModalStyles.noResults}>*No existen solicitudes asociadas</Text>
            </Box>
          ) : (
            <Table colorScheme='facebook'>
              <Thead>
                <Tr>
                  <Th
                    sx={
                      isMobile
                        ? { width: '60%', ...viewRequestsModalStyles.tHeadMobile }
                        : { width: '50%', ...viewRequestsModalStyles.tHead }
                    }
                  >
                    Institución
                  </Th>
                  <Th
                    sx={
                      isMobile
                        ? { width: '25%', ...viewRequestsModalStyles.tHeadMobile }
                        : { width: '30%', ...viewRequestsModalStyles.tHead }
                    }
                  >
                    Fecha
                  </Th>
                  <Th
                    sx={
                      isMobile
                        ? { width: '15%', ...viewRequestsModalStyles.tHeadMobile }
                        : { width: '20%', ...viewRequestsModalStyles.tHead }
                    }
                  >
                    Acción
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {viewRequests?.map((e: any) => {
                  const institution = e.Institution?.name
                  const date = new Date(e.createdAt)
                    .toISOString()
                    .split('T')[0]
                    .split('-')
                    .reverse()
                    .join('-')
                  return (
                    <Tr key={e.id}>
                      <Td
                        sx={
                          isMobile
                            ? viewRequestsModalStyles.tDataMobile
                            : viewRequestsModalStyles.tData
                        }
                      >
                        {institution}
                      </Td>
                      <Td
                        sx={
                          isMobile
                            ? viewRequestsModalStyles.tDataMobile
                            : viewRequestsModalStyles.tData
                        }
                      >
                        {date}
                      </Td>
                      <Td>
                        <Icon
                          sx={
                            isMobile
                              ? viewRequestsModalStyles.checkIconMobile
                              : { ...viewRequestsModalStyles.checkIcon }
                          }
                          as={CheckIcon as React.ElementType}
                          onClick={() => handleApprove(e.id)}
                        />
                        <Icon
                          sx={
                            isMobile
                              ? viewRequestsModalStyles.closeIconMobile
                              : { ...viewRequestsModalStyles.closeIcon }
                          }
                          as={CloseIcon as React.ElementType}
                          onClick={() => handleReject(e.id)}
                        />
                      </Td>
                    </Tr>
                  )
                })}
              </Tbody>
            </Table>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default ViewRequestsModal
