import {
  Box,
  Button,
  Icon,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
} from '@chakra-ui/react'
import { DownloadIcon, EmailIcon, Search2Icon, ViewIcon } from '@chakra-ui/icons'
import { useDispatch, useSelector } from 'react-redux/'

import { CandidatesDataField } from '../_types'
import { CandidatesTableProps } from './_types'
import { FC } from 'react'
import { Link } from 'react-router-dom'
import Loader from '../../loader'
import Paginator from '../../Paginator'
import { TbEyeCheck } from 'react-icons/tb'
import { candidatesFields } from '../../../utils/candidates'
import { candidatesTableStyles } from './styles'
import { colors } from '../../../utils/colors'
import { resumeDownloadRequest } from '../../../state/modules/user/actions'
import { sendViewRequisitionRequest } from '../../../state/modules/profiles/actions'

const CandidatesTable: FC<CandidatesTableProps> = ({
  filters,
  currentPage,
  setCurrentPage,
  setResumeName,
}) => {
  const profilesList = useSelector((state: any) => state?.profiles?.profilesList?.rows)
  const totalProfiles = useSelector((state: any) => state?.profiles?.profilesList?.count)
  const dispatch = useDispatch()

  const handleViewRequest = (userId: string, institutionId: string) => {
    const token = localStorage.getItem('access_token') ?? ''
    dispatch(sendViewRequisitionRequest({ userId, token, institutionId }))
  }

  const handleDownloadProfile = (userId: string, profile: any, institutionId: string) => {
    dispatch(resumeDownloadRequest({ userId, photo: 'true', institutionId }))
    const name = profile?.name + '_' + profile.lastName
    setResumeName(name)
  }

  return (
    <Box sx={candidatesTableStyles.container}>
      {filters === null ? (
        <Box sx={candidatesTableStyles.noResultsContainer}>
          <Text sx={candidatesTableStyles.noResults}>*No se han realizado búsquedas aún</Text>
        </Box>
      ) : profilesList ? (
        profilesList.length === 0 ? (
          <Box sx={candidatesTableStyles.noResultsContainer}>
            <Text sx={candidatesTableStyles.noResults}>
              *No existen individuos que coincidan con la búsqueda
            </Text>
          </Box>
        ) : (
          <>
            <TableContainer>
              <Table colorScheme='facebook'>
                <Thead>
                  <Tr>
                    {candidatesFields
                      .filter((e) => e.isShown)
                      .map((field: CandidatesDataField) => {
                        const label = field.id === 'positionType' ? 'Registrado como' : field.name

                        return (
                          <Td
                            sx={{
                              width: `${field.width}%`,
                              minWidth: `${field.width}%`,
                              ...candidatesTableStyles.tableHead,
                            }}
                            key={field.order}
                          >
                            {label}
                          </Td>
                        )
                      })}
                    <Td
                      sx={{
                        width: '5%',
                        minWidth: '5%',
                        ...candidatesTableStyles.tableHead,
                      }}
                    >
                      Acción
                    </Td>
                  </Tr>
                </Thead>
                <Tbody>
                  {profilesList?.map((e: any, i: number) => {
                    const profile = e.Profile
                    const preferences = e.Preferences
                    const province = profile?.Address?.Province?.description?.toLowerCase() ?? '-'
                    const department =
                      profile?.Address?.Department?.description.toLowerCase() ?? '-'
                    const locality = profile?.Address?.Locality?.description.toLowerCase() ?? '-'
                    const education =
                      e.UserTitles?.length > 0
                        ? e.UserTitles?.map((e: any) => (
                            <Text key={e.id}>{`*${
                              e.type === 'nodocente' ? 'no docente' : e.type
                            }: ${e.type === 'docente' ? e.teacherTitle : e.title}`}</Text>
                          ))
                        : '-'
                    const positions: any[] = []
                    const areas: any[] = []

                    preferences.forEach((element: any) => {
                      element?.Positions.forEach((e: any) => {
                        if (e.type === 'DOCENTE' || e.type === 'NO DOCENTE') {
                          positions.push(e)
                        }
                      })

                      element?.Subjects.forEach((e: any) => {
                        areas.push(e)
                      })
                    })

                    const isTeacher = positions?.find((e: any) => e.type === 'DOCENTE')
                    const isOther = positions?.find((e: any) => e.type === 'NO DOCENTE')

                    const positionType =
                      positions?.length > 0
                        ? isTeacher && isOther
                          ? 'Docente - No Docente'
                          : isTeacher
                          ? 'Docente'
                          : 'No Docente'
                        : '-'
                    const positionsInfo =
                      positions?.length > 0
                        ? positions.map((e: any) => (
                            <Text
                              sx={{ ...candidatesTableStyles.columnData }}
                              key={e.id}
                            >{`${e.description.toLowerCase()}`}</Text>
                          ))
                        : '-'
                    const educationAreas =
                      areas.filter((e: any) => e.type === 'CURRICULAR').length > 0
                        ? areas
                            .filter((e: any) => e.type === 'CURRICULAR')
                            .map((e: any) => (
                              <Text
                                sx={{ ...candidatesTableStyles.columnData }}
                                key={e.id}
                              >{`${e.description.toLowerCase()}`}</Text>
                            ))
                        : '-'

                    const extraEducationAreas =
                      areas.filter((e: any) => e.type === 'EXTRA-CURRICULAR').length > 0
                        ? areas
                            .filter((e: any) => e.type === 'EXTRA-CURRICULAR')
                            .map((e: any) => (
                              <Text
                                sx={{ ...candidatesTableStyles.columnData }}
                                key={e.id}
                              >{`${e.description.toLowerCase()}`}</Text>
                            ))
                        : '-'
                    return (
                      <Tr
                        key={e.id}
                        background={
                          e.ProfileVisualizations?.length > 0 ? 'white' : colors.unchecked
                        }
                      >
                        <Td sx={candidatesTableStyles.tableData}>{profile.name}</Td>
                        <Td
                          sx={{ textTransform: 'capitalize', ...candidatesTableStyles.tableData }}
                        >
                          {province}
                        </Td>
                        <Td
                          sx={{ textTransform: 'capitalize', ...candidatesTableStyles.tableData }}
                        >
                          {department}
                        </Td>
                        <Td
                          sx={{ textTransform: 'capitalize', ...candidatesTableStyles.tableData }}
                        >
                          {locality}
                        </Td>
                        <Td sx={{ ...candidatesTableStyles.tableData }}>{positionType}</Td>
                        <Td sx={{ ...candidatesTableStyles.tableData }}>{positionsInfo}</Td>

                        <Td sx={{ ...candidatesTableStyles.tableData }}>{educationAreas}</Td>
                        <Td sx={{ ...candidatesTableStyles.tableData }}>{extraEducationAreas}</Td>
                        <Td sx={{ ...candidatesTableStyles.tableData }}>{education}</Td>
                        <Td sx={candidatesTableStyles.tableDataAction}>
                          <Link
                            to={`/candidates/profile/${e.id}?institutionId=${filters.institutionId}`}
                            target='_blank'
                          >
                            <Tooltip label='Ver perfil' background={colors.principal}>
                              <Icon
                                cursor='pointer'
                                color={
                                  e.ProfileVisualizations?.length > 0 ? colors.green : colors.black
                                }
                                boxSize={'12px'}
                                as={ViewIcon as React.ElementType}
                                margin={'0 3px'}
                              />
                            </Tooltip>
                          </Link>
                          <Tooltip label='Ver datos privados' background={colors.principal}>
                            <Icon
                              sx={candidatesTableStyles.tableDataIcon}
                              as={EmailIcon as React.ElementType}
                              onClick={() => handleViewRequest(e.id, filters.institutionId)}
                            />
                          </Tooltip>
                          <Tooltip label='Descargar perfil' background={colors.principal}>
                            <Icon
                              sx={candidatesTableStyles.tableDataIcon}
                              as={DownloadIcon as React.ElementType}
                              onClick={() =>
                                handleDownloadProfile(e.id, profile, filters.institutionId)
                              }
                            />
                          </Tooltip>
                        </Td>
                      </Tr>
                    )
                  })}
                </Tbody>
              </Table>
            </TableContainer>
            <Paginator
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
              elementsLength={totalProfiles}
              elementsPerpage={10}
              elements={profilesList}
            />
          </>
        )
      ) : (
        <Loader props={candidatesTableStyles.loading} />
      )}
    </Box>
  )
}

export default CandidatesTable
