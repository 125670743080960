import * as Yup from 'yup'

import {
  AdsSearchDataField,
  AdsSearchFilterProps,
  Department,
  FilterFields,
  Locality,
  Position,
  Province,
  SearchFilter,
  Subject,
} from './_types'
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Icon,
  Input,
  Text,
  useMediaQuery,
} from '@chakra-ui/react'
import { Field, Form, Formik, FormikErrors, FormikTouched } from 'formik'
import React, { FC, useEffect, useState } from 'react'
import {
  departmentsClean,
  departmentsRequest,
  institutionTypesClean,
  institutionTypesRequest,
  levelmodalitiesClean,
  levelmodalitiesRequest,
  levelsClean,
  levelsRequest,
  localitiesClean,
  localitiesRequest,
  provincesClean,
  provincesRequest,
  suplenciesClean,
  suplenciesRequest,
} from '../../../state/modules/user/actions'
import {
  downloadAdsSpreadsheetRequest,
  jobTypesClean,
  jobTypesRequest,
  positionsClean,
  positionsRequest,
  subjectsClean,
  subjectsRequest,
  timeSlotsClean,
  timeSlotsRequest,
} from '../../../state/modules/ads/actions'
import { filterSvg, positionTypeOptions, searchAdsFields } from '../../../utils/adsSearch'
import { useDispatch, useSelector } from 'react-redux'

import { CloseIcon } from '@chakra-ui/icons'
import Select from 'react-select'
import { adsSearchFilterStyles } from './styles'
import { colors } from '../../../utils/colors'

const AdsSearchFilter: FC<AdsSearchFilterProps> = ({
  setCurrentPage,
  initialFilters,
  setFilters,
  filters,
}) => {
  const [isMobile] = useMediaQuery('(max-width: 600px)')
  const user = useSelector((state: any) => state?.user)
  const ads = useSelector((state: any) => state?.ads)

  const timeSlots = useSelector((state: any) => state?.ads?.timeSlotsList)
  const jobTypes = useSelector((state: any) => state?.ads?.jobTypesList)
  const [showExportButton, setShowExportButton] = useState<boolean>(false)
  const [showFilters, setShowFilters] = useState<boolean>(false)
  const [textField, setTextField] = useState<any>({
    referenceNumber: '',
    institution: '',
    title: '',
  })
  const provincias: Province[] = user?.provincesList?.map((prov: any) => {
    return { label: prov.description, value: prov.id }
  })
  const [selectedProvince, setSelectedProvince] = useState<any>([])
  const departamentos: Department[] = user?.departmentsList?.map((dpto: any) => {
    return { label: dpto.description, value: dpto.id }
  })
  const [selectedDepartment, setSelectedDepartment] = useState<any>([])
  const localidades: Locality[] = user?.localitiesList?.map((city: any) => {
    return { label: city.description, value: city.id }
  })
  const [selectedLocality, setSelectedLocality] = useState<any>([])
  const levelOptions = user?.levelsList?.map((level: any) => {
    return { label: level.description, value: level.id }
  })
  const [levels, setLevels] = useState<any[]>([])
  const [positionType, setPositionType] = useState<any>(null)
  const [positionOptions, setPositionOptions] = useState<any[]>([])
  const [position, setPosition] = useState<any[]>([])
  const [educationAreasOptions, setEducationAreasOptions] = useState<any[]>([])
  const [extraEducationAreasOptions, setExtraEducationAreasOptions] = useState<any[]>([])
  const [educationAreas, setEducationAreas] = useState<any[]>([])
  const [extraEducationAreas, setExtraEducationAreas] = useState<any[]>([])
  const [isTeacher, setIsTeacher] = useState<boolean>(false)
  const [suplencyOptions, setSuplencyOptions] = useState<any[]>([])
  const [suplencies, setSuplencies] = useState<any[]>([])
  const [timeSlotOptions, setTimeSlotOptions] = useState<any[]>([])
  const [timePeriod, setTimePeriod] = useState<any[]>([])
  const [jobTypeOptions, setJobTypeOptions] = useState<any[]>([])
  const [modality, setModality] = useState<any>(null)
  const [isClean, setIsClean] = useState<boolean>(true)

  const institutionTypesOptions = user?.institutionTypesList?.map((type: any) => {
    return { label: type.description, value: type.id }
  })

  const [institutionTypes, setInstitutionTypes] = useState<any[]>([])
  const levelModalitiesOptions = user?.levelModalitiesList?.map((level: any) => {
    return { label: level.description, value: level.levelmodalityid }
  })

  const [levelModalities, setLevelModalities] = useState<any[]>([])

  const dispatch = useDispatch()

  useEffect(() => {
    const associatedInstitutions =
      user?.userInformation?.UserInstitutions.filter((e: any) => e.isApproved).length > 0
        ? true
        : false

    setShowExportButton(associatedInstitutions)
  }, [user])

  useEffect(() => {
    const token = localStorage.getItem('access_token') ?? ''

    dispatch(provincesRequest({ token }))
    dispatch(timeSlotsRequest({ token }))
    dispatch(jobTypesRequest({ token }))
    dispatch(levelsRequest({ token }))
    dispatch(institutionTypesRequest({ token }))
    dispatch(levelmodalitiesRequest({ token }))
    dispatch(subjectsRequest({ token }))
    dispatch(positionsRequest({ token }))
    dispatch(suplenciesRequest({ token }))

    return () => {
      dispatch(provincesClean())
      dispatch(timeSlotsClean())
      dispatch(jobTypesClean())
      dispatch(levelsClean())
      dispatch(institutionTypesClean())
      dispatch(levelmodalitiesClean())
      dispatch(subjectsClean())
      dispatch(positionsClean())
      dispatch(suplenciesClean())
    }
  }, [])

  useEffect(() => {
    const token = localStorage.getItem('access_token') ?? ''

    if (
      selectedProvince !== undefined &&
      selectedProvince !== null &&
      selectedProvince.length === 1
    ) {
      dispatch(departmentsRequest({ token, provinceId: selectedProvince[0].value }))
      return () => {
        dispatch(departmentsClean())
      }
    }
  }, [selectedProvince])

  useEffect(() => {
    const token = localStorage.getItem('access_token') ?? ''

    if (
      selectedDepartment !== undefined &&
      selectedDepartment !== null &&
      selectedDepartment.length === 1
    ) {
      dispatch(
        localitiesRequest({
          token,
          departmentId: selectedDepartment[0].value,
        }),
      )
      return () => {
        dispatch(localitiesClean())
      }
    }
  }, [selectedDepartment])

  useEffect(() => {
    if (ads) {
      if (positionType?.value === 'DOCENTE') {
        setPositionOptions(
          ads?.positionsList
            ?.filter((pos: Position) => pos.type === 'DOCENTE')
            .map((pos: Position) => {
              return { value: pos.id, label: pos.description }
            }),
        )
        setEducationAreasOptions(
          ads?.subjectsList
            ?.filter((subject: Subject) => subject.type === 'CURRICULAR')
            .map((subject: Subject) => {
              return { value: subject.id, label: subject.description }
            }),
        )
        setExtraEducationAreasOptions(
          ads?.subjectsList
            ?.filter((subject: Subject) => subject.type === 'EXTRA-CURRICULAR')
            .map((subject: Subject) => {
              return { value: subject.id, label: subject.description }
            }),
        )
      } else if (positionType?.value === 'NO DOCENTE') {
        setPositionOptions(
          ads?.positionsList
            ?.filter((pos: any) => pos.type === 'NO DOCENTE')
            .map((pos: any) => {
              return { value: pos.id, label: pos.description }
            }),
        )
        setEducationAreasOptions([])
        setExtraEducationAreasOptions([])
      } else {
        setPositionOptions([])
        setEducationAreasOptions([])
        setExtraEducationAreasOptions([])
      }
    }
  }, [ads, positionType])

  useEffect(() => {
    if (user) {
      const suplencies = user?.suplenciesList?.map((suplency: any) => {
        return { value: suplency.id, label: suplency.description }
      })
      setSuplencyOptions(suplencies)
    }
  }, [user])

  useEffect(() => {
    if (timeSlots) {
      setTimeSlotOptions([])
      timeSlots.map((timeSlot: any) => {
        setTimeSlotOptions((prev: any) => [
          ...prev,
          { value: timeSlot?.id, label: timeSlot?.description },
        ])
      })
    }
  }, [timeSlots])

  useEffect(() => {
    if (jobTypes) {
      setJobTypeOptions([])
      jobTypes.map((jobType: any) => {
        setJobTypeOptions((prev: any) => [
          ...prev,
          { value: jobType?.id, label: jobType?.description },
        ])
      })
    }
  }, [jobTypes])

  const renderInput = (
    field: AdsSearchDataField,
    setFieldValue?: any,
    handleChange?: any,
    setFieldTouched?: any,
  ) => {
    switch (true) {
      case 'adProvince' === field.id:
        return (
          <Select
            placeholder='Selecciona tu provincia'
            name={field.id}
            styles={{
              menu: (baseStyles: any, state: any) => ({
                ...baseStyles,
              }),
            }}
            onBlur={() => setFieldTouched(field.id)}
            options={provincias}
            value={selectedProvince}
            onChange={(value: any) => {
              handleAddress(field.id, value, setFieldValue)
            }}
            isMulti
          />
        )

      case 'adDepartment' === field.id:
        return (
          <Select
            placeholder='Selecciona tu departamento o partido'
            name={field.id}
            styles={{
              menu: (baseStyles: any, state: any) => ({
                ...baseStyles,
              }),
            }}
            onBlur={() => setFieldTouched(field.id)}
            options={departamentos?.sort(
              (a: any, b: any) => a?.label?.split(' ')[1] - b?.label?.split(' ')[1],
            )}
            value={selectedDepartment}
            onChange={(value: any) => {
              handleAddress(field.id, value, setFieldValue)
            }}
            isDisabled={selectedProvince?.length > 1}
            isMulti
          />
        )
      case 'adLocality' === field.id:
        return (
          <Select
            placeholder='Selecciona tu localidad'
            name={field.id}
            styles={{
              menu: (baseStyles: any, state: any) => ({
                ...baseStyles,
              }),
            }}
            onBlur={() => setFieldTouched(field.id)}
            value={selectedLocality}
            onChange={(value: any) => {
              handleAddress(field.id, value, setFieldValue)
            }}
            isDisabled={selectedProvince?.length > 1 || selectedDepartment?.length > 1}
            options={localidades}
            isMulti
          />
        )
      case 'level' === field.id:
        return (
          <Select
            placeholder='Selecciona tu nivel'
            name={field.id}
            styles={{
              menu: (baseStyles, state) => ({
                ...baseStyles,
              }),
            }}
            onBlur={() => setFieldTouched(field.id)}
            isMulti
            options={levelOptions}
            onChange={(value) => {
              handleLevel(value, setFieldValue)
            }}
            value={levels}
          />
        )
      case 'levelModalities' === field.id:
        return (
          <Select
            placeholder='Selecciona tu característica'
            name={field.id}
            styles={{
              menu: (baseStyles, state) => ({
                ...baseStyles,
              }),
            }}
            onBlur={() => setFieldTouched(field.id)}
            isMulti
            options={levelModalitiesOptions}
            onChange={(value) => {
              handleLevelModalities(value, setFieldValue)
            }}
            value={levelModalities}
          />
        )

      case 'institutionTypes' === field.id:
        return (
          <Select
            placeholder='Selecciona tu modalidad'
            name={field.id}
            styles={{
              menu: (baseStyles, state) => ({
                ...baseStyles,
              }),
            }}
            onBlur={() => setFieldTouched(field.id)}
            isMulti
            options={institutionTypesOptions}
            onChange={(value) => {
              handleInstitutionTypes(value, setFieldValue)
            }}
            value={institutionTypes}
          />
        )

      case 'positionType' === field.id:
        return (
          <Select
            placeholder='Selecciona tu tipo de cargo'
            name={field.id}
            styles={{
              valueContainer: (baseStyles, state) => ({
                ...baseStyles,
              }),
            }}
            isClearable
            onBlur={() => setFieldTouched(field.id)}
            options={positionTypeOptions}
            value={positionType}
            onChange={(value: any) => {
              handleChangePositionType(value, setFieldValue)
            }}
          />
        )
      case 'position' === field.id:
        return (
          <Select
            placeholder='Selecciona tu puesto'
            name={field.id}
            options={positionOptions}
            onBlur={() => setFieldTouched(field.id)}
            value={position}
            onChange={(value) => {
              handleChangePosition(value, setFieldValue)
            }}
            isMulti
          />
        )
      case 'educationAreas' === field.id:
        return (
          <Select
            placeholder='Selecciona tu área'
            name={field.id}
            options={educationAreasOptions}
            value={educationAreas}
            isMulti
            onChange={(value) => {
              handleChangeAreas(field.id, value, setFieldValue)
            }}
          />
        )

      case 'extraEducationAreas' === field.id:
        return (
          <Select
            placeholder='Selecciona tu área'
            name={field.id}
            options={extraEducationAreasOptions}
            value={extraEducationAreas}
            isMulti
            onChange={(value) => {
              handleChangeAreas(field.id, value, setFieldValue)
            }}
          />
        )
      case 'modality' === field.id:
        return (
          <Select
            placeholder='Selecciona tu modalidad de trabajo'
            name={field.id}
            options={jobTypeOptions}
            onChange={(value) => {
              handleChangeModality(value, setFieldValue)
            }}
            value={modality}
          />
        )
      case 'timePeriod' === field.id:
        return (
          <Select
            placeholder='Selecciona franja horaria'
            name={field.id}
            options={timeSlotOptions}
            onChange={(value) => {
              handleChangeTimePeriod(value, setFieldValue)
            }}
            value={timePeriod}
            isMulti
          />
        )

      case 'suplencies' === field.id:
        return (
          <Select
            placeholder='Selecciona suplencias'
            name={field.id}
            options={suplencyOptions}
            onChange={(value) => {
              handleChangeSuplencies(value, setFieldValue)
            }}
            value={suplencies}
            isMulti
          />
        )

      default:
        return (
          <Field
            as={Input}
            name={field.id}
            placeholder={field.name}
            type={field.type}
            value={textField[field.id]}
            onChange={(e: any) => {
              handleChange(e)
              setTextField((prev: any) => ({ ...prev, [field.id]: e.target.value }))
            }}
          />
        )
    }
  }

  const initialValues: FilterFields = {
    referenceNumber: '',
    institution: '',
    title: '',
    adProvince: [],
    adDepartment: [],
    adLocality: [],
    level: [],
    positionType: '',
    position: [],
    educationAreas: [],
    extraEducationAreas: [],
    modality: '',
    timePeriod: [],
    suplencies: [],
    levelModalities: [],
    institutionTypes: [],
  }

  const validationSchema = Yup.object({
    referenceNumber: Yup.string(),
    institution: Yup.string(),
    title: Yup.string(),
    adProvince: Yup.array(),
    adDepartment: Yup.array(),
    adLocality: Yup.array(),
    level: Yup.array(),
    positionType: Yup.string(),
    position: Yup.array(),
    educationAreas: Yup.array(),
    extraEducationAreas: Yup.array(),
    modality: Yup.string(),
    timePeriod: Yup.array(),
    suplencies: Yup.array(),
  }).defined()

  const handleAddress = (name: string, value: any, setFieldValue: any) => {
    if (name === 'adProvince') {
      setSelectedProvince(value)
      setFieldValue(
        name,
        value?.map((item: any) => {
          return { id: item.value }
        }),
      )
      setSelectedDepartment([])
      setSelectedLocality([])
      setTimeout(() => {
        setFieldValue('adDepartment', [])
      }, 100)
      setTimeout(() => {
        setFieldValue('adLocality', [])
      }, 110)
    }
    if (name === 'adDepartment') {
      setSelectedDepartment(value)
      setFieldValue(
        name,
        value?.map((item: any) => {
          return { id: item.value }
        }),
      )
      setSelectedLocality([])
      setTimeout(() => {
        setFieldValue('adLocality', [])
      }, 101)
    }

    if (name === 'adLocality') {
      setSelectedLocality(value)
      setFieldValue(
        name,
        value?.map((item: any) => {
          return { id: item.value }
        }),
      )
    }
  }

  const handleLevel = (value: any, setFieldValue: any) => {
    setLevels(value)
    setFieldValue(
      'level',
      value?.map((item: any) => {
        return { id: item.value }
      }),
    )
  }

  const handleLevelModalities = (value: any, setFieldValue: any) => {
    setLevelModalities(value)
    setFieldValue(
      'levelModalities',
      value?.map((item: any) => {
        return { id: item.value }
      }),
    )
  }

  const handleInstitutionTypes = (value: any, setFieldValue: any) => {
    setInstitutionTypes(value)
    setFieldValue(
      'institutionTypes',
      value?.map((item: any) => {
        return { id: item.value }
      }),
    )
  }

  const handleChangePositionType = (value: any, setFieldValue: any) => {
    const fieldValue = value ? value.value : ''
    setPositionType(value)
    setFieldValue('positionType', fieldValue)
    setPosition([])
    setTimeout(() => {
      setFieldValue('position', [])
    }, 100)
  }

  const handleChangePosition = (value: any, setFieldValue: any) => {
    setPosition(value)
    setFieldValue(
      'position',
      value.map((item: any) => {
        return { id: item.value }
      }),
    )

    const isTeacher = value.find((item: any) => item.value === '20')
    if (isTeacher) setIsTeacher(true)
    else setIsTeacher(false)
  }

  const handleChangeAreas = (name: string, value: any, setFieldValue: any) => {
    if (name === 'educationAreas') {
      setFieldValue(
        'educationAreas',
        value.map((item: any) => {
          return { id: item.value }
        }),
      )
      setEducationAreas(value)
    }
    if (name === 'extraEducationAreas') {
      setFieldValue(
        'extraEducationAreas',
        value.map((item: any) => {
          return { id: item.value }
        }),
      )
      setExtraEducationAreas(value)
    }
  }

  const handleChangeSuplencies = (value: any, setFieldValue: any) => {
    setSuplencies(value)
    setFieldValue(
      'suplencies',
      value.map((item: any) => {
        return { id: item.value }
      }),
    )
  }

  const handleChangeTimePeriod = (value: any, setFieldValue: any) => {
    setTimePeriod(value)
    setFieldValue(
      'timePeriod',
      value.map((item: any) => {
        return { id: item.value }
      }),
    )
  }

  const handleChangeModality = (value: any, setFieldValue: any) => {
    setFieldValue('modality', value?.value)
    setModality(value)
  }

  const handleSubmit = (values: any) => {
    const zone = {
      provinceId: values.adProvince.map((item: any) => item.id),
      departmentId: values.adDepartment.map((item: any) => item.id),
      localityId: values.adLocality.map((item: any) => item.id),
    }
    const updatedValues: SearchFilter = {
      referenceNumber: values.referenceNumber,
      title: values.title,
      institutionName: values.institution,
      zone,
      level: values.level.map((item: any) => item.id),
      jobPosition: values.position.map((item: any) => item.id),
      suplencies: values.suplencies.map((item: any) => item.id),
      timeSlot: values.timePeriod.map((item: any) => item.id),
      jobType: values.modality,
      subjects: [...values.educationAreas, ...values.extraEducationAreas],
      characteristics: values.levelModalities.map((item: any) => item.id),
      modalities: values.institutionTypes.map((item: any) => item.id),
      positionType: values.positionType,
    }

    setFilters(updatedValues)
    setIsClean(false)
    setCurrentPage(1)
  }

  const handleCleanFilters = (resetForm: any) => {
    resetForm()
    setFilters(initialFilters)
    setTextField({ referenceNumber: '', institution: '', title: '' })
    setSelectedProvince([])
    setSelectedDepartment([])
    setSelectedLocality([])
    setLevels([])
    setPositionType({})
    setPosition([])
    setEducationAreas([])
    setExtraEducationAreas([])
    setSuplencies([])
    setTimePeriod([])
    setLevelModalities([])
    setInstitutionTypes([])
    setModality({})
    setIsTeacher(false)
    setIsClean(true)
  }

  const handleDownloadSpreadsheet = (filters: SearchFilter) => {
    const token = localStorage.getItem('access_token') ?? ''
    dispatch(downloadAdsSpreadsheetRequest({ token, filters }))
  }
  return (
    <Box
      sx={
        isMobile
          ? !showFilters
            ? { display: 'flex', justifyContent: 'space-between' }
            : adsSearchFilterStyles.containerMobile
          : !showFilters
          ? { display: 'flex', justifyContent: 'space-between' }
          : adsSearchFilterStyles.container
      }
    >
      {!showFilters ? (
        isMobile ? (
          <>
            <Text color={colors.principal}>Filtros</Text>
            <Icon
              sx={adsSearchFilterStyles.filterIcon}
              viewBox='0 0 28 21'
              onClick={() => setShowFilters(true)}
            >
              <path d={filterSvg.d} fill={filterSvg.fill} />
            </Icon>
          </>
        ) : (
          <>
            <Text color={colors.principal}>Filtros</Text>
            <Icon
              sx={adsSearchFilterStyles.filterIcon}
              viewBox='2 2 28 19'
              onClick={() => setShowFilters(true)}
            >
              <path d={filterSvg.d} fill={filterSvg.fill} />
            </Icon>
          </>
        )
      ) : (
        <CloseIcon
          color={'#004876'}
          sx={adsSearchFilterStyles.filterIcon}
          onClick={() => setShowFilters(false)}
        />
      )}
      {showFilters && (
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({ setFieldValue, resetForm, handleChange, errors, touched, setFieldTouched }) => {
            const touchedFields = touched as FormikTouched<any>
            const errorFields = errors as FormikErrors<any>

            return (
              <Form>
                <Box sx={isMobile ? adsSearchFilterStyles.formMobile : adsSearchFilterStyles.form}>
                  {searchAdsFields
                    .filter((e) => e.isFiltered)
                    .sort((e1, e2) => e1.order - e2.order)
                    .map((field: AdsSearchDataField) => {
                      const isShown =
                        (field.id === 'educationAreas' || field.id === 'extraEducationAreas') &&
                        !isTeacher
                          ? 'none'
                          : 'initial'
                      return (
                        <FormControl
                          display={isShown}
                          sx={
                            isMobile
                              ? adsSearchFilterStyles.inputMobile
                              : adsSearchFilterStyles.input
                          }
                          key={field.order}
                        >
                          <FormLabel>
                            {field.name}
                            {field.required && '*'}
                          </FormLabel>
                          {renderInput(field, setFieldValue, handleChange, setFieldTouched)}
                          {errorFields[field.id] && touchedFields[field.id] && (
                            <Text sx={adsSearchFilterStyles.error}>{`${
                              errorFields[field.id]
                            }`}</Text>
                          )}
                        </FormControl>
                      )
                    })}
                  <Box
                    sx={
                      isMobile
                        ? adsSearchFilterStyles.buttonBoxMobile
                        : adsSearchFilterStyles.buttonBox
                    }
                  >
                    <Button sx={adsSearchFilterStyles.searchButton} type='submit'>
                      Buscar
                    </Button>
                    {showExportButton && (
                      <Button
                        sx={adsSearchFilterStyles.cleanFilters}
                        isDisabled={isClean || filters.zone.provinceId.length < 1}
                        onClick={() => handleDownloadSpreadsheet(filters)}
                      >
                        Exportar
                      </Button>
                    )}

                    <Button
                      sx={adsSearchFilterStyles.cleanFilters}
                      isDisabled={isClean}
                      onClick={() => handleCleanFilters(resetForm)}
                    >
                      Limpiar filtros
                    </Button>
                  </Box>
                </Box>
              </Form>
            )
          }}
        </Formik>
      )}
    </Box>
  )
}

export default AdsSearchFilter
