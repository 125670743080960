import { useEffect, useState } from 'react'
import { Box, SimpleGrid } from '@chakra-ui/react'
import InformationProfile from './informationProfile'
import Advertsing from '../advertsing'
import InformationResume from './informationResume'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { userInformationCleanRequest } from '../../../state/modules/user/actions'
import { loginClean } from '../../../state/modules/login/actions'
import Loader from '../../loader'

const UserComponet = () => {
  const [isLogged, setIsLogged] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { userInformation } = useSelector((state: any) => state.user)

  useEffect(() => {
    const session = localStorage.getItem('access_token')
    if (session !== null) setIsLogged(true)
  }, [isLogged])

  useEffect(() => {
    if (isLogged) {
      if (userInformation === undefined || userInformation === null) {
        handleLogout()
      }
    }
  }, [userInformation]) // aqui para desloggearme

  const handleLogout = () => {
    dispatch(userInformationCleanRequest())
    localStorage.removeItem('access_token')
    localStorage.removeItem('refresh_token')
    setIsLogged(false)
    dispatch(loginClean())
  }
  useEffect(() => {
    return () => {
      sessionStorage.setItem('career', 'success_relocated')
    }
  }, [])
  const loadingStyles = {
    width: '100%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }
  return (
    <>
      {isLogged ? (
        <SimpleGrid
          columns={[1, null, 3]} // Mostrar una columna en dispositivos móviles y 3 columnas en escritorio
          width='100%'
          css={{
            '@media screen and (min-width: 768px)': {
              display: 'grid',
              gridTemplateColumns: '25% 55% 20%',
            },
          }}
          minWidth='fit-content'
        >
          <Box width='100' id='container'>
            <InformationProfile />
          </Box>
          <Box width='100'>
            <InformationResume />
          </Box>
          <Box width='100'>
            <Advertsing />
          </Box>{' '}
        </SimpleGrid>
      ) : (
        <Loader props={loadingStyles} />
      )}
    </>
  )
}

export default UserComponet
